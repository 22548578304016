import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      d="m22.532 7.98-10.73-4.91a.68.68 0 0 0-.604 0L.468 7.98a.73.73 0 0 0-.352.3.8.8 0 0 0-.115.466.86.86 0 0 0 .125.459.8.8 0 0 0 .343.307l3.284 1.532-.719 5.828a.9.9 0 0 0 .04.37c.04.118.105.225.19.312C5.53 19.724 8.454 20.948 11.5 21c3.041-.078 5.953-1.324 8.2-3.508a.8.8 0 0 0 .19-.312.9.9 0 0 0 .04-.37l-.682-5.828 2.22-1.01v9.443a.765.765 0 0 0 1.531 0V8.731a.8.8 0 0 0-.119-.457.73.73 0 0 0-.348-.294m-4.175 8.616c-1.928 1.718-4.344 2.692-6.857 2.765-2.511-.076-4.926-1.05-6.856-2.765l.575-4.924 5.98 2.734c.193.082.41.082.603 0l5.98-2.734zm-6.857-3.83L2.71 8.732 11.5 4.71l8.79 4.02z"
    />
  </svg>
);
export default Icon;
