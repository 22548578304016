export const inProduction =
  process.env.NEXT_PUBLIC_APP_ENV === "production" ||
  process.env.APP_ENV === "production";

export const apiConfig = {
  GRAPHQL_ENDPOINT: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
};

export const amplifyConfig = {
  development: {
    Auth: {
      identityPoolId: "eu-central-1:5c9bb417-173f-4468-a739-75fc5823d634",
      region: "eu-central-1",
      identityPoolRegion: "eu-central-1",
      userPoolId: "eu-central-1_85mEzZuvP",
      userPoolWebClientId: "2csgonindl04pifndir8dv55r",
      mandatorySignIn: true,
      federationTarget: "COGNITO_USER_POOLS",
      oauth: {
        domain: "lassieappdevelopment.auth.eu-central-1.amazoncognito.com",
        scope: [
          "phone",
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin",
        ],
        responseType: "code",
      },
    },
    Storage: {
      AWSS3: {
        bucket: "lassie-app-user-files-development",
        region: "eu-central-1",
      },
    },
  },
  production: {
    Auth: {
      identityPoolId: "eu-central-1:9b70e752-5a52-4383-81be-a86dbb01350a",
      region: "eu-central-1",
      identityPoolRegion: "eu-central-1",
      userPoolId: "eu-central-1_ytJ4XfnYa",
      userPoolWebClientId: "2qh2cck68eb3u99rus0ur2k5cg",
      mandatorySignIn: true,
      federationTarget: "COGNITO_USER_POOLS",
      oauth: {
        domain: "auth.lassie.co",
        scope: [
          "phone",
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin",
        ],
        responseType: "code",
      },
    },
    Storage: {
      AWSS3: {
        bucket: "lassie-app-user-files",
        region: "eu-central-1",
      },
    },
  },
};
