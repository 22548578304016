import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      stroke={props.color}
      strokeWidth={0.417}
      d="m12 18.75 4.85 3.069c.888.562 1.975-.27 1.741-1.321l-1.285-5.772 4.288-3.888c.784-.71.363-2.054-.666-2.14l-5.644-.501-2.209-5.454a1.15 1.15 0 0 0-2.15 0L8.716 8.184l-5.644.502c-1.029.085-1.45 1.43-.667 2.14l4.29 3.888-1.286 5.772c-.234 1.051.853 1.883 1.741 1.32z"
    />
  </svg>
);
export default Icon;
