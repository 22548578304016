/**
 * The config files can't use TS imports so we have a version of some utils in
 * JS here.
 */

const getDefaultLocaleByDomain = (domain) => {
  switch (domain) {
    case "lassie.co":
      return "sv";
    case "lassie.de":
      return "de";
    case "lassie.fr":
      return "fr";
    default:
      throw new Error(
        `Cannot get sitemap locale for domain ${process.env.DOMAIN}`
      );
  }
};

const getLocalesByDomain = (domain) => {
  switch (domain) {
    case "lassie.co":
      return ["sv", "en"];
    case "lassie.de":
      return ["en", "de"];
    case "lassie.fr":
      return ["en", "fr"];
  }
};

module.exports = {
  getDefaultLocaleByDomain,
  getLocalesByDomain,
};
