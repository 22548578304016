import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="#14267B" d="M9.945 10.75a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
    <path
      fill="#14267B"
      fillRule="evenodd"
      d="M12.445 2.5a2.75 2.75 0 0 0-2.75 2.75v5.5h-1.5v-5.5a4.25 4.25 0 0 1 8.5 0v5.5h-1.5v-5.5a2.75 2.75 0 0 0-2.75-2.75"
      clipRule="evenodd"
    />
    <path
      fill="#14267B"
      fillRule="evenodd"
      d="M4.457 9.361A2.75 2.75 0 0 1 7.179 7h10.53a2.75 2.75 0 0 1 2.723 2.361l1.429 10a2.75 2.75 0 0 1-2.722 3.139H5.75a2.75 2.75 0 0 1-2.723-3.139zM7.179 8.5a1.25 1.25 0 0 0-1.237 1.073l-1.429 10A1.25 1.25 0 0 0 5.751 21h13.388a1.25 1.25 0 0 0 1.237-1.427l-1.429-10A1.25 1.25 0 0 0 17.71 8.5z"
      clipRule="evenodd"
    />
    <path fill="#14267B" d="M16.945 10.75a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
    <path
      fill="#14267B"
      fillRule="evenodd"
      d="M12.445 2.5a2.75 2.75 0 0 0-2.75 2.75v5.5h-1.5v-5.5a4.25 4.25 0 0 1 8.5 0v5.5h-1.5v-5.5a2.75 2.75 0 0 0-2.75-2.75"
      clipRule="evenodd"
    />
    <path fill="#14267B" d="M9.945 10.75a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
  </svg>
);
export default Icon;
