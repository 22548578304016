import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      d="M11.991 10.943c2.201 0 3.341 1.888 3.731 3.976l.024.147.082.015c1.634.346 3.453 1.755 3.543 3.47l.005.199a3.753 3.753 0 0 1-3.767 3.75 4.94 4.94 0 0 1-2.22-.585 3.28 3.28 0 0 0-3.017-.005 4.96 4.96 0 0 1-2.246.59 3.75 3.75 0 0 1-3.75-3.75c0-1.804 1.662-3.31 3.359-3.669l.08-.015.026-.147c.377-2.021 1.785-3.976 3.938-3.971zm-.071 1.514c-1.657 0-2.656 1.636-2.656 3.293a.75.75 0 0 1-.75.75l-.154.005c-1.17.08-2.484 1.054-2.484 2.245A2.247 2.247 0 0 0 8.11 21a3.46 3.46 0 0 0 1.561-.415l.233-.114a4.79 4.79 0 0 1 4.188.119l.208.103c.422.191.877.296 1.327.307a2.25 2.25 0 0 0 2.25-2.25c0-1.243-1.585-2.25-2.827-2.25l-.102-.007a.75.75 0 0 1-.648-.743c0-1.657-.722-3.293-2.38-3.293M3.626 7.27c1.657 0 2.887 2.012 2.887 4.729 0 1.657-1.23 3-2.887 3S.711 13.657.711 12c0-2.586 1.258-4.729 2.915-4.729m16.663 0c1.657 0 3 2.194 3 4.729a3 3 0 1 1-6 0c0-2.588 1.343-4.729 3-4.729m-16.663 1.5c-.828 0-1.415 1.636-1.415 3.229 0 .828.587 1.5 1.415 1.5s1.387-.672 1.387-1.5c0-1.64-.558-3.229-1.387-3.229m16.663 0c-.829 0-1.5 1.642-1.5 3.229a1.5 1.5 0 0 0 3 0c0-1.587-.672-3.229-1.5-3.229M8.514 1.4c1.657 0 3 2.208 3 4.858a3 3 0 0 1-6 0c0-2.461 1.343-4.858 3-4.858m6.802 0c1.656 0 3 2.449 3 4.858a3 3 0 1 1-6 0c0-2.462 1.343-4.858 3-4.858M8.514 2.9c-.828 0-1.5 1.634-1.5 3.358a1.5 1.5 0 0 0 3 0c0-1.805-.672-3.358-1.5-3.358m6.802 0c-.829 0-1.5 1.634-1.5 3.358a1.5 1.5 0 0 0 3 0c0-1.803-.672-3.358-1.5-3.358"
    />
  </svg>
);
export default Icon;
