import Head from "next/head";
import React, { ReactNode, ReactElement } from "react";
import { ErrorBoundary } from "@sentry/nextjs";
import Header from "../Header";
import Footer from "../Footer";
import styles from "./Layout.module.css";
import { SiteNavigation_siteCollection_items_navigationCollection_items } from "../../../__generated__/SiteNavigation";
import ConsentAndTagManager from "./ConsentAndTagManager";
import { ErrorComponentFullPage } from "./ErrorPage";
import { useWebTranslation } from "translations";
import { getResolvedDomainByOrg, Org } from "utils/site";
import { useCanonicalUrl } from "hooks/useCanonicalUrl";

interface LayoutProps {
  children: ReactNode;
  title?: string;
  description?: string;
  slim?: boolean;
  background?: string;
  noHeader?: boolean;
  noRobot?: boolean;
  seoTitle?: string;
  seoKeywords?: string[];
  seoNoIndex?: boolean;
  seoNoFollow?: boolean;
  seo?: {
    hrefLang: Array<{ locale: string; uri: string; org: Org }> | null;
  };
  navigationItems?:
    | (SiteNavigation_siteCollection_items_navigationCollection_items | null)[]
    | undefined;
  siteLanguages?: (string | null)[] | null | undefined;
}

function Layout({
  children,
  title = "",
  description = "",
  slim = false,
  noRobot = false,
  seoKeywords,
  seoNoFollow,
  seoNoIndex,
  seoTitle,
  seo,
}: LayoutProps): ReactElement {
  const { t } = useWebTranslation();

  const canonicalUrl = useCanonicalUrl();

  const robots = [
    seoNoIndex === true ? "noindex" : undefined,
    seoNoFollow === true ? "nofollow" : undefined,
  ].filter((x) => x !== undefined);

  return (
    <div>
      <Head>
        <title>{title}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        {seo?.hrefLang?.map(({ locale, uri, org }) => (
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale}
            href={new URL(
              uri,
              `https://${getResolvedDomainByOrg(org)}`
            ).toString()}
          />
        ))}
        <link rel="canonical" href={canonicalUrl} />
        {noRobot && <meta name="robots" content="none" />}
        {!!description && (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
          </>
        )}
        <meta name="author" content="Lassie" />
        <meta property="og:title" content={seoTitle ?? title ?? "Lassie"} />
        <meta
          property="og:image"
          content="https://www.lassie.co/images/hero-image-og.png"
        />
        <meta
          name="image"
          content="https://www.lassie.co/images/hero-image-og.png"
        />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="723156671567662" />
        {seoKeywords && seoKeywords.length > 0 && (
          <meta name="keywords" content={seoKeywords.join(",")} />
        )}
        {robots.length > 0 && (
          <meta name="robots" content={robots.join(", ")} />
        )}
      </Head>
      <ConsentAndTagManager includeHotJar={!slim} />
      <ErrorBoundary
        fallback={
          <ErrorComponentFullPage
            title={t("500.title")}
            description={t("500.description")}
            imgSrc="/images/error-500.svg"
          />
        }
      >
        {children}
      </ErrorBoundary>
    </div>
  );
}

function LayoutPage({
  children,
  title = "",
  description = "",
  slim = false,
  noHeader,
  background,
  noRobot,
  seoTitle,
  seoNoIndex,
  seoNoFollow,
  seoKeywords,
  seo,
  siteLanguages,
  navigationItems,
}: LayoutProps): ReactElement {
  const { t } = useWebTranslation();

  return (
    <Layout
      siteLanguages={siteLanguages}
      navigationItems={navigationItems}
      noRobot={noRobot}
      title={title}
      description={description}
      slim={slim}
      seoKeywords={seoKeywords}
      seoNoIndex={seoNoIndex}
      seoTitle={seoTitle}
      seoNoFollow={seoNoFollow}
      seo={seo}
    >
      {!noHeader && (
        <Header
          siteLanguages={siteLanguages}
          navigationItems={navigationItems ?? []}
        />
      )}
      <main
        className={styles.container}
        style={background ? { background } : {}}
      >
        <ErrorBoundary
          fallback={
            <ErrorComponentFullPage
              title={t("500.title")}
              description={t("500.description")}
              imgSrc="/images/error-500.svg"
            />
          }
        >
          {children}
        </ErrorBoundary>
      </main>
      <Footer siteLanguages={siteLanguages} />
    </Layout>
  );
}

export { Layout, LayoutPage };
