import {
  MenuButton,
  MenuItems,
  MenuLink,
  MenuPopover,
  useMenuButtonContext,
} from "@reach/menu-button";
import NextLink from "next/link";
import classNames from "classnames";
import { Icon } from "@/ui-kit";
import { Typography } from "ui-kit/Typography";
import styles from "./styles.module.css";
import { MenuGroup } from "./types";
import { useEffect } from "react";

const TopLevelButton = ({
  label,
  isExpanded,
}: {
  label: string;
  isExpanded?: boolean;
}) => {
  return (
    <MenuButton
      className={classNames(
        styles.topLevelItem,
        isExpanded && styles.isExpanded
      )}
    >
      <Typography variant="body3" style={{ textWrap: "nowrap" }}>
        <strong>{label}</strong>
      </Typography>
      <div className={styles.iconWrapper}>
        <Icon name={isExpanded ? "chevron-up" : "chevron-down"} size="S" />
      </div>
    </MenuButton>
  );
};

export const TopLevelLink = ({
  href,
  label,
}: {
  href: string;
  label: string;
}) => {
  return (
    <NextLink href={href} className={styles.topLevelItem}>
      <Typography variant="body3" style={{ textWrap: "nowrap" }}>
        <strong>{label}</strong>
      </Typography>
    </NextLink>
  );
};

export const DesktopMenu = ({
  item,
  onMenuToggle,
}: {
  item: MenuGroup;
  onMenuToggle?: (state: boolean) => void;
}) => {
  const { isExpanded } = useMenuButtonContext();

  useEffect(() => {
    onMenuToggle && onMenuToggle(isExpanded);
  }, [isExpanded, onMenuToggle]);

  return (
    <>
      <TopLevelButton label={item.group} isExpanded={isExpanded} />

      <MenuPopover
        portal={true}
        className={styles.menuList}
        style={{ position: "fixed" }}
        position={(targetRect) => {
          if (!targetRect) {
            return {
              top: 0,
              left: 0,
            };
          }

          return {
            top: targetRect.top + targetRect?.height,
            left: targetRect?.left,
          };
        }}
      >
        <MenuItems className={styles.menuItems}>
          {item.items.map((menuItem) => {
            return (
              <div key={menuItem.headline.name}>
                {"subMenu" in menuItem ? (
                  <div key={menuItem.headline.name}>
                    <p className={styles.category}>
                      <strong>{menuItem.headline.name}</strong>
                    </p>
                    {menuItem.subMenu?.map((subMenuItem) => {
                      if (!subMenuItem.uri) {
                        return null;
                      }

                      return (
                        <MenuLink
                          className={styles.topLevelItem}
                          key={subMenuItem.name}
                          as={NextLink}
                          href={subMenuItem.uri}
                        >
                          <Typography variant="body3">
                            <strong>{subMenuItem.name}</strong>
                          </Typography>
                        </MenuLink>
                      );
                    })}
                  </div>
                ) : (
                  <MenuLink
                    key={menuItem.headline.name}
                    as={NextLink}
                    href={menuItem.headline.uri ?? ""}
                    className={styles.topLevelItem}
                  >
                    <Typography variant="body3">
                      <strong>{menuItem.headline.name}</strong>
                    </Typography>
                  </MenuLink>
                )}
              </div>
            );
          })}
        </MenuItems>
      </MenuPopover>
    </>
  );
};
