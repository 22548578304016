/**
 * Can be used in for example switch statements to emit a compile time warning
 * if it is not exhaustive.
 * @example
 * ```
 *  type t = 'a' | 'b'
 *
 *  const foo: t = ...
 *
 *  switch (foo) {
 *    case: a:
 *      ...
 *    default:
 *      assertNever(foo) // Error since case `b` isn't handled
 *  }
 * ```
 * @param x anything
 */
export function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x);
}
