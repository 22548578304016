import { getSiteConfig, Locale, sites } from "../config/site";
import { inProduction } from "../../config";

export type Domain = "lassie.de" | "lassie.co" | "lassie.fr";
export type Org = "ger" | "swe" | "fra";
export enum Domains {
  de = "lassie.de",
  co = "lassie.co",
  fr = "lassie.fr",
}

/*
Dont try to rewrite this into something more fancy,
the env vars needs to be written like this to be understood by the interpreter
 */
export const getDomain = (): Domain => {
  const frontend = process.env.NEXT_PUBLIC_DOMAIN;
  const backend = process.env.DOMAIN;

  const resolvedDomain = backend || frontend;

  if (resolvedDomain === Domains.de) {
    return Domains.de;
  }
  if (resolvedDomain === Domains.co) {
    return Domains.co;
  }
  if (resolvedDomain === Domains.fr) {
    return Domains.fr;
  }

  throw new Error("Valid domain env var not found");
};

export const getSiteUrl = (path?: string | null): string => {
  const trimmedPath = (path ?? "").trim();
  const cleanedPath = trimmedPath.startsWith("/")
    ? trimmedPath.substring(1)
    : trimmedPath;

  const base = process.env.NEXT_PUBLIC_SITE_URL || `https://www.${getDomain()}`;
  return cleanedPath ? `${base}/${cleanedPath}` : base;
};

export const getOrg = (): Org => {
  const frontend = process.env.NEXT_PUBLIC_DOMAIN;
  const backend = process.env.DOMAIN;

  const resolvedDomain = backend || frontend;

  if (resolvedDomain === Domains.de) {
    return "ger";
  }
  if (resolvedDomain === Domains.co) {
    return "swe";
  }
  if (resolvedDomain === Domains.fr) {
    return "fra";
  }

  throw new Error("Valid domain env var not found");
};

export const isOrg = (org: Org): boolean => org === getOrg();

export const getLocales = (): Array<Locale> => getSiteConfig()["locales"];

export const getDefaultLocale = (): Locale => getSiteConfig()["defaultLocale"];

export const stripeKey = (): string => {
  return process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY ?? "";
};

export const getAppStoreUrl = () => getSiteConfig()["appStoreUrl"];

export const getPlayMarketUrl = () =>
  "https://play.google.com/store/apps/details?id=com.teamlassie.lassieapp";

export const getTagManagerId = () => {
  return getSiteConfig()["tagManagerId"][
    inProduction ? "production" : "development"
  ];
};

export const getAuthenticationMethod = () => {
  return getSiteConfig()["authentication"];
};

export const getCurrencyCode = () => getSiteConfig()["currency"]["code"];
export const getCurrencySymbol = () => getSiteConfig()["currency"]["symbol"];
export const getUserCentricId = () => getSiteConfig()["userCentricId"];

export const getCountryCode = () => getSiteConfig()["countryCode"];

export const getStartOffsetInDays = () =>
  getSiteConfig()["insurance"]["startOffsetInDays"];

export const getMinimumAgeInDays = () =>
  getSiteConfig()["insurance"]["minimumAgeInDays"];

export const getSelectablePaymentMethods = () =>
  getSiteConfig()["payment"]["selectablePaymentMethods"];

export const getMeetingBookingUrl = () => {
  const meetingBookingUrl =
    getSiteConfig()["contactDetails"]["meetingBookingUrl"];

  return meetingBookingUrl !== "" ? meetingBookingUrl : null;
};
export const getContactEmail = () => getSiteConfig()["contactDetails"]["email"];

export const getDateFormat = () => getSiteConfig()["dateFormat"];

export const getCopays = () => getSiteConfig()["insurance"]["copays"];
export const getDeductibles = () => getSiteConfig()["insurance"]["deductibles"];
export const getPreventiveCareAddOnLimits = () =>
  getSiteConfig()["insurance"]["preventiveCareAddOnLimits"] || [];
export const getLimits = () => getSiteConfig()["insurance"]["limits"] || [];

export const getDefaultCopay = () =>
  getSiteConfig()["insurance"]["defaultCopay"];
export const getDefaultDeductible = () =>
  getSiteConfig()["insurance"]["defaultDeducible"];
export const getDefaultLimit = () =>
  getSiteConfig()["insurance"]["defaultLimit"];

export const getResolvedDomainByOrg = (org: Org) => sites[org].resolvedDomain;
export const getResolvedDomain = () => getSiteConfig().resolvedDomain;

export const getCampaigns = () => getSiteConfig().campaigns;

export const getLeadAllianceProgramId = () =>
  getSiteConfig()["leadAllianceProgramId"];
