import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M4.788 7.322a1.08 1.08 0 0 0-1.48 0 .98.98 0 0 0-.021 1.406l7.205 6.849L11.982 17l1.497-1.416 7.212-6.862a.98.98 0 0 0 .021-1.41 1.087 1.087 0 0 0-1.504-.02l-7.226 6.868z"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
