import React, { FC, useMemo } from "react";
import { useRouter } from "next/router";
import { useWebTranslation } from "../../translations";
import styles from "./LanguageSelector.module.css";
import { LanguagesMap } from "../../constants/LangaugesMap";
import { getDomain } from "utils/site";
import { sites } from "config/site";
import { Option, SingleValue } from "./SelectorComponents";
import Select, {
  MultiValue,
  SingleValue as SingleValueType,
} from "react-select";
import { SelectorOption } from "components/Selector/types";
import { LanguageGroup, LanguageOption, LanguageSelectorProps } from "./types";
import Colors from "ui-kit/colors";

export const LanguageSelector: FC<LanguageSelectorProps> = ({
  siteLanguages,
  id,
}) => {
  const { i18n } = useWebTranslation();
  const router = useRouter();
  const languages =
    siteLanguages?.map((language) => LanguagesMap[language!]) || [];

  const changeLanguage = (
    option: MultiValue<LanguageOption> | SingleValueType<LanguageOption>
  ) => {
    if (!option) return;

    const { locale, domain } = option as LanguageOption;

    if (domain === getDomain() && locale !== i18n?.language) {
      router.push("/", "/", { locale });

      return;
    }

    if (domain !== getDomain()) {
      window.location.href = `https://${domain}${locale === "en" ? "/en" : ""}`;
      return;
    }
  };

  const languageOptions = Object.values(sites).reduce((options, config) => {
    return [
      ...options,
      {
        label: LanguagesMap[config.defaultLocale].countryName as string,
        options: config.locales.map((locale) => {
          return {
            id: `${locale}-${config.domain}`,
            name: `${LanguagesMap[locale === "en" ? "en-GB" : locale].name}`,
            locale,
            flag: config.defaultLocale,
            domain: config.domain,
            countryName: LanguagesMap[config.defaultLocale].countryName || "",
          };
        }),
      },
    ];
  }, [] as Array<LanguageGroup>);

  const selectedLanguage = useMemo(
    () =>
      languageOptions
        .flatMap((option) => option.options)
        .find(
          (language) =>
            language.domain === getDomain() &&
            language.locale === i18n?.language
        ),
    [languages, i18n?.language]
  );

  return (
    <div className={styles.wrapper}>
      <Select
        instanceId={id}
        getOptionLabel={(option: SelectorOption) => option?.name ?? ""}
        getOptionValue={(option: SelectorOption) => option?.id ?? ""}
        closeMenuOnSelect
        value={selectedLanguage}
        isSearchable={false}
        isClearable={false}
        onChange={changeLanguage}
        options={languageOptions}
        menuPlacement="top"
        styles={{
          control: (styles) => ({
            ...styles,
            borderRadius: 16,
          }),
          menuList: (styles) => ({
            ...styles,
            padding: 0,
            maxHeight: "none",
          }),
          menu: (styles) => ({
            ...styles,
            borderRadius: 24,
            padding: 16,
          }),
          option: (styles, { isSelected, isFocused }) => ({
            ...styles,
            cursor: "pointer",
            padding: 12,
            display: "flex",
            alignItems: "center",
            borderRadius: 8,
            backgroundColor: isSelected
              ? Colors.grey200
              : isFocused
              ? Colors.grey100
              : "transparent",
            color: "black",
          }),
          singleValue: (styles) => ({
            ...styles,
            display: "flex",
            alignItems: "center",
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: 12,
            display: "flex",
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: Colors.blue300,
          },
        })}
        components={{
          SingleValue,
          Option,
        }}
      />
    </div>
  );
};
