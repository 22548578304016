import { SpeciesEnum } from "../../../__generated__/globalTypes";
import {
  Legend,
  Package,
  Packages,
} from "../../interfaces/components/Insurance";
import { getWebTranslation } from "../../translations";

export const legend: (species: SpeciesEnum) => Legend = (species) => ({
  veterinaryCare: {
    title: getWebTranslation("insurance_packages.legend.veterinary_care.title"),
    description: getWebTranslation(
      "insurance_packages.legend.veterinary_care.description"
    ),
  },
  lifeInsurance: {
    title: getWebTranslation("insurance_packages.legend.life_insurance.title"),
    description: getWebTranslation(
      "insurance_packages.legend.life_insurance.description"
    ),
  },
  hiddenFaults: {
    title: getWebTranslation("insurance_packages.legend.hidden_faults.title"),
    description: getWebTranslation(
      "insurance_packages.legend.hidden_faults.description"
    ),
  },
  vetFreeCalls: {
    title: getWebTranslation("insurance_packages.legend.vet_free_calls.title"),
    description: getWebTranslation(
      "insurance_packages.legend.vet_free_calls.description"
    ),
  },
  dentalDisease: {
    title: getWebTranslation("insurance_packages.legend.dental_disease.title"),
    description: getWebTranslation(
      species === SpeciesEnum.cat
        ? "insurance_packages.legend.dental_disease.description_cat"
        : "insurance_packages.legend.dental_disease.description_dog"
    ),
  },
  advancedDiagnostics: {
    title: getWebTranslation(
      "insurance_packages.legend.advanced_diagnostics.title"
    ),
    description: getWebTranslation(
      "insurance_packages.legend.advanced_diagnostics.description"
    ),
  },
  medicine: {
    title: getWebTranslation("insurance_packages.legend.medicine.title"),
    description: getWebTranslation(
      "insurance_packages.legend.medicine.description"
    ),
  },
  cesareanSection: {
    title: getWebTranslation(
      "insurance_packages.legend.cesarean_section.title"
    ),
    description: getWebTranslation(
      "insurance_packages.legend.cesarean_section.description"
    ),
  },
  rehabilitation: {
    title: getWebTranslation("insurance_packages.legend.rehabilitation.title"),
    description: getWebTranslation(
      "insurance_packages.legend.rehabilitation.description"
    ),
  },
  specialMeals: {
    title: getWebTranslation("insurance_packages.legend.special_meals.title"),
    description: getWebTranslation(
      "insurance_packages.legend.special_meals.description"
    ),
  },
  deductible: {
    title: getWebTranslation("insurance_packages.legend.deductible.title"),
    description: getWebTranslation(
      "insurance_packages.legend.deductible.description"
    ),
  },
});

const getPackage = (key: string) => {
  return {
    title: getWebTranslation(`insurance_packages.package.${key}.title`),
    subTitle: getWebTranslation(`insurance_packages.package.${key}.sub_title`),
    veterinaryCare: getWebTranslation(
      `insurance_packages.package.${key}.veterinary_care`
    ),
    lifeInsurance: getWebTranslation(
      `insurance_packages.package.${key}.life_insurance`
    ),
    hiddenFaults: getWebTranslation(
      `insurance_packages.package.${key}.hidden_faults`
    ),
    vetFreeCalls: getWebTranslation(
      `insurance_packages.package.${key}.vet_free_calls`
    ),
    dentalDisease: getWebTranslation(
      `insurance_packages.package.${key}.dental_disease`
    ),
    advancedDiagnostics: getWebTranslation(
      `insurance_packages.package.${key}.advanced_diagnostics`
    ),
    medicine: getWebTranslation(`insurance_packages.package.${key}.medicine`),
    cesareanSection: getWebTranslation(
      `insurance_packages.package.${key}.cesarean_section`
    ),
    rehabilitation: getWebTranslation(
      `insurance_packages.package.${key}.rehabilitation`
    ),
    specialMeals: getWebTranslation(
      `insurance_packages.package.${key}.special_meals`
    ),
    deductible: getWebTranslation(
      `insurance_packages.package.${key}.deductible`
    ),
    ipid: getWebTranslation(`insurance_packages.package.${key}.ipid`),
  };
};

const dogPackages: () => Package[] = () => [
  {
    img: "/images/insurance/dog/mini.png",
    popular: false,
    ...getPackage("dog.mini"),
  },
  {
    img: "/images/insurance/dog/mellan.png",
    popular: true,
    ...getPackage("dog.between"),
  },
  {
    img: "/images/insurance/dog/stor.png",
    popular: false,
    ...getPackage("dog.big"),
  },
];

const catPackages: () => Package[] = () => [
  {
    img: "/images/insurance/cat/mini.png",
    popular: false,
    ...getPackage("cat.mini"),
  },
  {
    img: "/images/insurance/cat/mellan.png",
    popular: true,
    ...getPackage("cat.between"),
  },
  {
    img: "/images/insurance/cat/stor.png",
    popular: false,
    ...getPackage("cat.big"),
  },
];

export const packages: () => Packages = () => ({
  dog: dogPackages(),
  cat: catPackages(),
});
