import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#14267B"
      fillRule="evenodd"
      d="M4.75 4.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M1 6.75a3.75 3.75 0 1 1 7.368.99l2.928 1.672c.067-.076.138-.144.205-.203.28-.245.676-.489 1.112-.717.886-.464 2.103-.949 3.354-1.334 1.247-.383 2.576-.683 3.683-.75.55-.034 1.09-.014 1.554.11.457.12.95.372 1.212.888.05.096.077.2.083.303l-.01.17a.75.75 0 0 1-.368.523L15.824 12l6.296 3.598a.74.74 0 0 1 .378.597.74.74 0 0 1-.083.401c-.262.514-.755.766-1.21.887-.464.123-1.005.143-1.555.11-1.107-.068-2.436-.367-3.683-.751-1.25-.385-2.468-.87-3.354-1.334-.436-.228-.832-.472-1.112-.717a2 2 0 0 1-.205-.203L8.57 16.145l-.108.068q.038.263.038.537a3.75 3.75 0 1 1-.569-1.986C9.84 13.36 9.79 10.376 7.688 9.08A3.75 3.75 0 0 1 1 6.75m13.313 4.386-1.642-.938q.231-.163.638-.377c.785-.411 1.912-.864 3.099-1.23 1.19-.365 2.393-.629 3.333-.686q.128-.008.244-.01zm-1.642 2.666q.231.163.638.377c.785.411 1.912.864 3.099 1.23 1.19.365 2.393.629 3.333.686q.127.008.244.01l-5.672-3.241zm-2.005-.582L12.8 12l-2.136-1.22c.2.8.2 1.642 0 2.44M2.5 16.75a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
