import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill={props.color} clipPath="url(#prefix__clip0_4214_829)">
      <path
        fillRule="evenodd"
        d="M5.883 4.662a1.833 1.833 0 1 0 0 3.666 1.833 1.833 0 0 0 0-3.666m-.834 1.833a.833.833 0 1 1 1.667 0 .833.833 0 0 1-1.667 0M8.05 10.495a1.833 1.833 0 1 1 3.666 0 1.833 1.833 0 0 1-3.667 0m1.833-.833a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666"
        clipRule="evenodd"
      />
      <path d="M10.903 6.182a.5.5 0 1 0-.707-.707l-5.333 5.333a.5.5 0 1 0 .707.707z" />
      <path
        fillRule="evenodd"
        d="M9.33 1.208a1.833 1.833 0 0 0-2.894 0L5.65 2.217a.83.83 0 0 1-.807.308l-.977-.178A1.833 1.833 0 0 0 1.735 4.48l.187 1.028a.83.83 0 0 1-.27.776L.625 7.18a1.833 1.833 0 0 0-.089 2.676l.879.879a.83.83 0 0 1 .24.672l-.111 1.11a1.833 1.833 0 0 0 1.976 2.01l1.62-.135a.83.83 0 0 1 .762.368l.538.806a1.833 1.833 0 0 0 2.972.109l.746-.958a.83.83 0 0 1 .74-.318l.98.098a1.833 1.833 0 0 0 2.006-2.007l-.098-.979a.83.83 0 0 1 .317-.74l1.066-.829a1.833 1.833 0 0 0 0-2.894l-1.01-.785a.83.83 0 0 1-.307-.807l.177-.977A1.833 1.833 0 0 0 11.9 2.347l-.977.178a.83.83 0 0 1-.807-.308zm-2.105.614a.833.833 0 0 1 1.316 0l.784 1.009c.42.539 1.104.8 1.776.678l.976-.178a.833.833 0 0 1 .97.97l-.178.976c-.122.672.139 1.356.678 1.775l1.009.785a.833.833 0 0 1 0 1.316l-1.065.828a1.83 1.83 0 0 0-.7 1.63l.099.979a.833.833 0 0 1-.912.912l-.98-.098a1.83 1.83 0 0 0-1.629.699l-.745.958a.833.833 0 0 1-1.351-.05l-.538-.806a1.83 1.83 0 0 0-1.678-.81l-1.619.135a.833.833 0 0 1-.898-.913l.11-1.11a1.83 1.83 0 0 0-.527-1.48l-.879-.878a.833.833 0 0 1 .04-1.216l1.026-.897a1.83 1.83 0 0 0 .596-1.708L2.72 4.3a.833.833 0 0 1 .97-.969l.976.178a1.83 1.83 0 0 0 1.775-.678z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
export default Icon;
