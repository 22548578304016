import React, { ReactNode, CSSProperties } from "react";
import { FC } from "../../types";
import styles from "./Flex.module.css";

type FlexRowProps = {
  children: ReactNode;
  justifyContent?:
    | "space-between"
    | "center"
    | "flex-end"
    | "flex-start"
    | "baseline";
  alignItems?: "center" | "flex-start" | "flex-end" | "baseline";
  className?: string;
  style?: CSSProperties;
  wrap?: boolean;
  reverse?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
};

export const FlexRow: FC<FlexRowProps> = ({
  className,
  style,
  children,
  justifyContent,
  alignItems,
  wrap,
  reverse,
  onClick,
}) => (
  <div
    onClick={onClick}
    className={styles.flexRow + (className ? ` ${className}` : "")}
    style={{
      ...(justifyContent ? { justifyContent } : {}),
      ...(reverse ? { flexDirection: "row-reverse" } : {}),
      ...(alignItems ? { alignItems } : {}),
      ...(wrap ? { flexWrap: "wrap" } : {}),
      ...style,
    }}
  >
    {children}
  </div>
);

export const FlexCol: FC<FlexRowProps> = ({
  className,
  children,
  justifyContent,
  alignItems,
  style,
}) => (
  <div
    className={styles.flexCol + (className ? ` ${className}` : "")}
    style={{
      ...(justifyContent ? { justifyContent } : {}),
      ...(alignItems ? { alignItems } : {}),
      ...style,
    }}
  >
    {children}
  </div>
);

FlexRow.fragments = {};

FlexRow.defaultProps = {
  justifyContent: undefined,
  alignItems: undefined,
  wrap: false,
};
