import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M6.5 2a.75.75 0 0 1 .75.75v1.5h9v-1.5a.75.75 0 0 1 1.5 0v1.5h.75a3 3 0 0 1 3 3V18.5a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7.25a3 3 0 0 1 3-3h.75v-1.5A.75.75 0 0 1 6.5 2M5 5.75a1.5 1.5 0 0 0-1.5 1.5v1.152A3 3 0 0 1 5 8h13.5a3 3 0 0 1 1.5.402V7.25a1.5 1.5 0 0 0-1.5-1.5zM20 11a1.5 1.5 0 0 0-1.5-1.5H5A1.5 1.5 0 0 0 3.5 11v7.5A1.5 1.5 0 0 0 5 20h13.5a1.5 1.5 0 0 0 1.5-1.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
