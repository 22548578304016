import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M6 3.377V4.5c0 1.874.858 3.546 2.205 4.647.71.583 1.547.993 2.442 1.2.89.206 1.816.206 2.706 0m-3.696 1.28a7.5 7.5 0 0 1-2.222-1.176 6.75 6.75 0 0 1-5.84-5.569.75.75 0 0 1 .584-.86q1.15-.244 2.321-.43V2.72a.75.75 0 0 1 .645-.743A49 49 0 0 1 12 1.5c2.326 0 4.615.162 6.854.477a.75.75 0 0 1 .646.743v.872q1.166.186 2.321.43a.75.75 0 0 1 .584.86 6.75 6.75 0 0 1-5.84 5.57c-.67.514-1.42.911-2.222 1.175.11.65.315 1.282.609 1.873h.423c1.037 0 1.875.84 1.875 1.875v2.7a3.75 3.75 0 0 1 3 3.675.75.75 0 0 1-.75.75h-15a.75.75 0 0 1-.75-.75 3.75 3.75 0 0 1 3-3.674v-2.701c0-1.035.84-1.875 1.875-1.875h.424a6.7 6.7 0 0 0 .608-1.873m1.03 1.873h2.626a8.2 8.2 0 0 1-.436-1.55 7.5 7.5 0 0 1-1.754 0c-.094.53-.24 1.05-.435 1.55M8.25 18h7.5v-2.625a.375.375 0 0 0-.375-.375h-6.75a.375.375 0 0 0-.375.375zm5.103-7.653a6 6 0 0 0 2.442-1.2A5.99 5.99 0 0 0 18 4.5V3.376A48 48 0 0 0 12 3a48 48 0 0 0-6 .377m13.476 1.73a7.46 7.46 0 0 1-.957 3.103 5.25 5.25 0 0 0 2.236-2.873 46 46 0 0 0-1.28-.23m-14.952 0q-.642.107-1.28.23A5.25 5.25 0 0 0 5.482 8.21a7.46 7.46 0 0 1-.957-3.102M7.5 19.5A2.25 2.25 0 0 0 5.379 21H18.62a2.25 2.25 0 0 0-2.121-1.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
