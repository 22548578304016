import classNames from "classnames";
import { Typography } from "ui-kit/Typography";
import Colors from "../../colors";
import styles from "./Badge.module.css";
import { CSSProperties } from "react";

export const Badge = ({
  text,
  color,
  className,
  style,
}: {
  text: string;
  color: Colors;
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <Typography
      variant="body3"
      className={classNames(styles.badge, className)}
      color="blue300"
      style={{ background: color, ...style }}
    >
      <strong>{text}</strong>
    </Typography>
  );
};
