import { i18n } from "next-i18next";
import { SpeciesEnum } from "../../__generated__/globalTypes";

export type PolicyContentGroups = string[];

export type PackageContent = {
  image: any;
  tagline: string;
  title: string;
  limit: Limit;
  compactText?: string;
  subTitle?: string;
  content: (
    animalName: string,
    species: SpeciesEnum | null
  ) => PackageContentItem[];
};

export type Limit = 3000000 | 6000000 | 16000000;
export type Deductible = 0 | 150000 | 350000;
export type Copay = 0 | 0.15 | 0.25;
export type LifeUtilityAddOn = "breeding" | "hunting" | "";

type PackageContentNoTexts = {
  id: string;
  active: boolean;
  iconName: string;
};

export type PackageContentItem = PackageContentNoTexts & {
  title: string;
  text: string;
  additional?: string;
};

export const MAX_AGE_DOG = 300;
export const MAX_AGE_CAT = 300;
export const MIN_LIFE_LIMIT_CAT = 1500;
export const MIN_LIFE_LIMIT_DOG = 2500;
export const MAX_LIFE_LIMIT_CAT = 20000;
export const MAX_LIFE_LIMIT_DOG = 50000;

const getContentsWithLabels: (
  contents: PackageContentNoTexts[],
  key: string
) => PackageContentItem[] = (contents, key) => {
  return contents.map((element, index) => {
    const elementWithTexts: PackageContentItem = {
      ...element,
      title:
        i18n?.t(`insurance_data.package_content.${key}.${index}.title`, {
          ns: "app",
        }) || "",
      text:
        i18n?.t(`insurance_data.package_content.${key}.${index}.text`, {
          ns: "app",
        }) || "",
    };
    const additionalKey = `insurance_data.package_content.${key}.${index}.additional`;
    if (i18n?.exists(additionalKey, { ns: "app" })) {
      elementWithTexts.additional = i18n?.t(additionalKey, { ns: "app" });
    }
    return elementWithTexts;
  });
};

const contentSmall: () => PackageContentItem[] = () =>
  getContentsWithLabels(
    [
      {
        id: "0",
        active: true,
        iconName: "dog-health",
      },
      {
        id: "1",
        iconName: "vet-meeting",
        active: true,
      },
      {
        id: "2",
        iconName: "dental-damage",
        active: true,
      },
      {
        id: "5",
        active: false,
        iconName: "health-hands",
      },
      {
        id: "6",
        active: true,
        iconName: "love-angel",
      },
      {
        id: "7",
        active: true,
        iconName: "shield-home",
      },
      {
        id: "8",
        active: true,
        iconName: "shield-home",
      },
      {
        id: "9",
        active: false,
        iconName: "scan-radiology",
      },
      {
        id: "10",
        active: false,
        iconName: "syringe",
      },
      {
        id: "11",
        active: false,
        iconName: "medication",
      },
      {
        id: "12",
        active: false,
        iconName: "dog-food",
      },
      {
        id: "13",
        active: false,
        iconName: "dog-jump",
      },
      {
        id: "14",
        active: false,
        iconName: "dog-mouth-protection",
      },
      {
        id: "15",
        active: false,
        iconName: "medical-scalpel",
      },
      {
        id: "16",
        iconName: "milk-teeth",
        active: false,
      },
    ],
    "small"
  );

const contentMedium: () => PackageContentItem[] = () =>
  getContentsWithLabels(
    [
      {
        id: "0",
        active: true,
        iconName: "dog-health",
      },
      {
        id: "1",
        iconName: "vet-meeting",
        active: true,
      },
      {
        id: "2",
        iconName: "dental-damage",
        active: true,
      },
      {
        id: "5",
        active: true,
        iconName: "health-hands",
      },
      {
        id: "6",
        active: true,
        iconName: "love-angel",
      },
      {
        id: "7",
        active: true,
        iconName: "shield-home",
      },
      {
        id: "8",
        active: true,
        iconName: "shield-home",
      },
      {
        id: "9",
        active: true,
        iconName: "scan-radiology",
      },
      {
        id: "10",
        active: true,
        iconName: "syringe",
      },
      {
        id: "11",
        active: true,
        iconName: "medication",
      },
      {
        id: "12",
        active: true,
        iconName: "dog-food",
      },
      {
        id: "13",
        active: true,
        iconName: "dog-jump",
      },
      {
        id: "14",
        active: true,
        iconName: "dog-mouth-protection",
      },
      {
        id: "15",
        active: true,
        iconName: "medical-scalpel",
      },
      {
        id: "16",
        iconName: "milk-teeth",
        active: true,
      },
    ],
    "medium"
  );

const contentLarge: () => PackageContentItem[] = () =>
  getContentsWithLabels(
    [
      {
        id: "0",
        active: true,
        iconName: "dog-health",
      },
      {
        id: "1",
        iconName: "vet-meeting",
        active: true,
      },
      {
        id: "2",
        iconName: "dental-damage",
        active: true,
      },
      {
        id: "5",
        active: true,
        iconName: "health-hands",
      },
      {
        id: "6",
        active: true,
        iconName: "love-angel",
      },
      {
        id: "7",
        active: true,
        iconName: "shield-home",
      },
      {
        id: "8",
        active: true,
        iconName: "shield-home",
      },
      {
        id: "9",
        active: true,
        iconName: "scan-radiology",
      },
      {
        id: "10",
        active: true,
        iconName: "syringe",
      },
      {
        id: "11",
        active: true,
        iconName: "medication",
      },
      {
        id: "12",
        active: true,
        iconName: "dog-food",
      },
      {
        id: "13",
        active: true,
        iconName: "dog-jump",
      },
      {
        id: "14",
        active: true,
        iconName: "dog-mouth-protection",
      },
      {
        id: "15",
        active: true,
        iconName: "medical-scalpel",
      },
      {
        id: "16",
        iconName: "milk-teeth",
        active: true,
      },
    ],
    "large"
  );

export const getContentItems = (limit: Limit) => {
  switch (limit) {
    case 3000000:
      return contentSmall();
    case 6000000:
      return contentMedium();
    case 16000000:
      return contentLarge();
    default:
      throw new Error(`Unknown limit ${limit}`);
  }
};

// Ids that ends a group, based on the order of policy content above
export const policyContentGroups: PolicyContentGroups = ["1", "3", "6", "8"];

export const limitsAndDeductibles = [
  {
    limit: 3000000,
    deductible: 150000,
  },
  {
    limit: 6000000,
    deductible: 150000,
  },
  {
    limit: 16000000,
    deductible: 150000,
  },
  {
    limit: 3000000,
    deductible: 350000,
  },
  {
    limit: 6000000,
    deductible: 350000,
  },
  {
    limit: 16000000,
    deductible: 350000,
  },
  {
    limit: 3000000,
    deductible: 0,
  },
  {
    limit: 6000000,
    deductible: 0,
  },
  {
    limit: 16000000,
    deductible: 0,
  },
];

export const ipids = [
  {
    limit: 3000000,
    href: "https://docs.lassie.co/ipid/latest/lassie-ipid-mini.pdf",
  },
  {
    limit: 6000000,
    href: "https://docs.lassie.co/ipid/latest/lassie-ipid-mellan.pdf",
  },
  {
    limit: 16000000,
    href: "https://docs.lassie.co/ipid/latest/lassie-ipid-stor.pdf",
  },
];

export const prepurchaseInfoInsurance =
  "https://docs.lassie.co/terms-and-conditions/latest/forkopsinformation-hund-och-kattforsakring.pdf";
export const prepurchaseInfoLassie =
  "https://docs.lassie.co/terms-and-conditions/latest/forkopsinformation.pdf";
export const termsAndConditions =
  "https://docs.lassie.co/terms-and-conditions/latest/villkor-hund-och-kattforsakring.pdf";
export const termsAndConditionsOldV5 =
  "https://docs.lassie.co/terms-and-conditions/villkor-hund-och-kattforsakring-juli-2023.pdf";
export const termsAndConditionsOldV4 =
  "https://docs.lassie.co/terms-and-conditions/villkor-hund-och-kattforsakring-juni-2023.pdf";
export const termsAndConditionsOldV3 =
  "https://docs.lassie.co/terms-and-conditions/villkor-hund-och-kattforsakring-januari-2023.pdf";
export const termsAndConditionsOldV2 =
  "https://docs.lassie.co/terms-and-conditions/villkor-hund-och-kattforsakring-maj-2022-v2.pdf";
export const termsAndConditionsOld =
  "https://docs.lassie.co/terms-and-conditions/villkor-hund-och-kattforsakring-januari-2022.pdf";
export const termsAndConditionsOldV1_2 =
  "https://docs.lassie.co/terms-and-conditions/villkor-hund-och-kattforsakring-maj-2022-v1.2.pdf";
