import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M10.375 6a.75.75 0 0 0-.75.75v2.375H7.25a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75h2.375v2.375c0 .414.336.75.75.75h3.75a.75.75 0 0 0 .75-.75v-2.375h2.375a.75.75 0 0 0 .75-.75v-3.75a.75.75 0 0 0-.75-.75h-2.375V6.75a.75.75 0 0 0-.75-.75zm.75 3.875V7.5h2.25v2.375a.75.75 0 0 0 .75.75H16.5v2.25h-2.375a.75.75 0 0 0-.75.75V16h-2.25v-2.375a.75.75 0 0 0-.75-.75H8v-2.25h2.375a.75.75 0 0 0 .75-.75"
      clipRule="evenodd"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M3.878 22.378q.304.054.61.083a7.8 7.8 0 0 0 3.163-.338l4.6-.493c6.145 0 11.249-4.554 11.249-10.315S18.395 1 12.25 1 1 5.554 1 11.315c0 2.76 1.181 5.255 3.083 7.095.287.277.368.548.331.736a4.43 4.43 0 0 1-.976 2.007.75.75 0 0 0 .44 1.225M2.5 11.315C2.5 6.511 6.799 2.5 12.25 2.5S22 6.511 22 11.315s-4.299 8.815-9.75 8.815c-.94.001-1.878-.121-2.787-.365a.75.75 0 0 0-.623.11A6.25 6.25 0 0 1 5.337 21c.254-.49.439-1.016.548-1.561v-.001c.173-.873-.266-1.63-.759-2.107-1.637-1.583-2.626-3.7-2.626-6.016m5.15 10.808 4.6-.493c-.957.001-1.912-.11-2.842-.332a7.7 7.7 0 0 1-1.757.825"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
