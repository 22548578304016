import React, { ReactElement } from "react";

export type GutterSizes =
  | 0
  | 0.25
  | 0.5
  | 1
  | 1.5
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | number;
export interface GutterProps {
  size: GutterSizes;
  horizontal?: boolean;
}
const Gutter = ({ size, horizontal }: GutterProps): ReactElement => (
  <div
    style={{ [horizontal ? "width" : "height"]: size * 8, flex: "0 0 auto" }}
  />
);
Gutter.defaultProps = {
  horizontal: false,
};

export default Gutter;
