import { Menu } from "@reach/menu-button";
import { MenuBarProps } from "./types";
import styles from "./styles.module.css";
import { DesktopMenu, TopLevelLink } from "./components";

export const MenuBarDesktop = ({
  navigationItems,
  onMenuToggle,
}: MenuBarProps) => {
  return (
    <nav className={styles.wrapper}>
      {navigationItems.map((item) => {
        return "group" in item ? (
          <Menu key={item.group}>
            <DesktopMenu item={item} onMenuToggle={onMenuToggle} />
          </Menu>
        ) : (
          <TopLevelLink
            key={item.name}
            label={item.name}
            href={item.uri as string}
          />
        );
      })}
    </nav>
  );
};
