import classNames from "classnames";
import styles from "./Hamburger.module.css";

export const Hamburger = ({
  isOpen,
  onClick,
}: {
  isOpen: boolean;
  onClick: () => void;
}) => {
  return (
    <button onClick={onClick} className={styles.container}>
      <div
        style={{
          transform: isOpen
            ? "translate(2px) rotate(45deg)"
            : "translate(0) rotate(0)",
        }}
        className={classNames(styles.bar, styles.barTop)}
      ></div>
      <div
        style={{
          transform: isOpen
            ? "translate(2px) rotate(-45deg)"
            : "translate(0) rotate(0)",
        }}
        className={classNames(styles.bar, styles.barBottom)}
      />
    </button>
  );
};
