import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M12.25 2A6.25 6.25 0 0 0 6 8.25v1.879c.61.504 1 1.267 1 2.121v2a2.75 2.75 0 1 1-5.5 0v-2a2.75 2.75 0 0 1 3-2.739V8.25a7.75 7.75 0 1 1 15.5 0v1.261q.123-.01.25-.011A2.75 2.75 0 0 1 23 12.25v2c0 1.072-.613 2-1.508 2.454-.05 1.367-.346 2.595-1.296 3.472-.934.863-2.357 1.25-4.32 1.314A2.25 2.25 0 0 1 13.75 23h-1a2.25 2.25 0 0 1 0-4.5h1c.976 0 1.806.621 2.118 1.49 1.807-.066 2.775-.421 3.31-.916.467-.43.72-1.074.796-2.088A2.75 2.75 0 0 1 17.5 14.25v-2c0-.854.39-1.617 1-2.121V8.25A6.25 6.25 0 0 0 12.25 2m2.25 18.75a.75.75 0 0 0-.75-.75h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 .75-.75M4.25 11C3.56 11 3 11.56 3 12.25v2a1.25 1.25 0 1 0 2.5 0v-2c0-.69-.56-1.25-1.25-1.25M19 12.25a1.25 1.25 0 1 1 2.5 0v2a1.25 1.25 0 1 1-2.5 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
