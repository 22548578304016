import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#14267B"
      fillRule="evenodd"
      d="M11.75 0A2.25 2.25 0 0 0 9.5 2.25V5.5h-.25A1.75 1.75 0 0 0 7.5 7.25v1.5q.001.268.076.512A2.75 2.75 0 0 0 6 11.75v9a2.75 2.75 0 0 0 2.75 2.75h6a2.75 2.75 0 0 0 2.75-2.75v-9a2.75 2.75 0 0 0-1.576-2.488Q16 9.018 16 8.75v-1.5a1.75 1.75 0 0 0-1.75-1.75H14V2.25A2.25 2.25 0 0 0 11.75 0M16 18v-4.5H7.5V18zm-8.5 1.5H16v1.25c0 .69-.56 1.25-1.25 1.25h-6c-.69 0-1.25-.56-1.25-1.25zm0-7.75V12H16v-.25c0-.69-.56-1.25-1.25-1.25h-6c-.69 0-1.25.56-1.25 1.25m5-6.25V2.25a.75.75 0 0 0-1.5 0V5.5zM9.25 7a.25.25 0 0 0-.25.25v1.5c0 .138.112.25.25.25h5a.25.25 0 0 0 .25-.25v-1.5a.25.25 0 0 0-.25-.25z"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
