import classNames from "classnames";
import { Typography } from "ui-kit/Typography";
import styles from "./MiniSwitch.module.css";
import { useEffect, useState } from "react";

function Option<Value>({
  value,
  option,
  onChange,
}: {
  value: Value;
  option: { label: string; value: Value };
  onChange: (value: Value) => void;
}) {
  return (
    <button
      className={classNames(styles.option)}
      onClick={() => onChange(option.value)}
    >
      <Typography
        variant="body3"
        color={value === option.value ? "blue300" : "grey500"}
      >
        {value === option.value ? (
          <strong>{option.label}</strong>
        ) : (
          option.label
        )}
      </Typography>
    </button>
  );
}

export function MiniSwitch<Value = unknown>({
  options,
  value,
  onChange,
  className,
}: {
  options: [{ label: string; value: Value }, { label: string; value: Value }];
  value: Value;
  onChange: (value: Value) => void;
  className?: string;
}) {
  const [internalValue, setInternalValue] = useState(
    value === options[0].value ? options[0].value : options[1].value
  );

  return (
    <div className={classNames(styles.container, className)}>
      <Option
        value={internalValue}
        option={options[0]}
        onChange={(value) => {
          setInternalValue(value);
          onChange(value);
        }}
      />
      <div
        className={classNames(
          styles.switch,
          internalValue === options[1].value && styles.switchRight
        )}
        onClick={() => {
          const newValue =
            internalValue === options[0].value
              ? options[1].value
              : options[0].value;
          setInternalValue(newValue);

          onChange(newValue);
        }}
      >
        <div className={styles.indicator} />
      </div>
      <Option
        value={internalValue}
        option={options[1]}
        onChange={(value) => {
          setInternalValue(value);
          onChange(value);
        }}
      />
    </div>
  );
}
