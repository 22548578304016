import Link from "next/link";
import Image from "next/image";
import styles from "./TuvBadges.module.css";
import { CSSProperties } from "react";

export const TuvBadges = ({
  withLinks,
  style,
}: {
  withLinks?: boolean;
  style?: CSSProperties;
}) => {
  return (
    <div className={styles.tuvBadges} style={style}>
      {withLinks ? (
        <>
          <Link href="https://www.tuev-saar.de/SC46092">
            <Image
              src="/images/tuv/tuv-kuzu.svg"
              height={80}
              width={152}
              alt=""
            />
          </Link>
          <Link href="https://www.tuev-saar.de/SC46160">
            <Image
              src="/images/tuv/tuv-plv.svg"
              height={80}
              width={152}
              alt=""
            />
          </Link>
        </>
      ) : (
        <>
          <Image
            src="/images/tuv/tuv-kuzu.svg"
            height={80}
            width={152}
            alt=""
          />
          <Image src="/images/tuv/tuv-plv.svg" height={80} width={152} alt="" />
        </>
      )}
    </div>
  );
};
