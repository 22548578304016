import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      d="M20.871 4.827a1 1 0 0 0-.094.012H2.898l-.011.002h-.004l-.018-.002c-.025.001-.05.008-.074.012a1 1 0 0 0-.125.029l-.008.002a1 1 0 0 0-.086.035l-.045.024a1 1 0 0 0-.111.078 1 1 0 0 0-.063.056l-.007.006q-.017.018-.03.04l-.01.013q-.016.02-.033.043a1 1 0 0 0-.049.09q-.011.022-.021.047a1 1 0 0 0-.027.09l-.014.048q-.004.035-.006.069-.001.016-.004.033-.002.018-.004.035v12.75c0 .337.112.646.3.896-.154-.275-.147-.633.175-.912a.75.75 0 1 0 1.014 1.106c-.271.263-.583.273-.832.15.24.164.53.26.843.26h16.5a1.5 1.5 0 0 0 1.5-1.5V5.729a.7.7 0 0 0-.074-.464l.008.017a1 1 0 0 0-.074-.127l-.034-.043a.74.74 0 0 0-.275-.21.7.7 0 0 0-.236-.075 1 1 0 0 0-.092 0m.447.14a1 1 0 0 0-.107-.06c.037.02.074.037.107.06M4.828 6.34h14.14l-7.07 6.482Zm-1.18.955L9.15 12.34l-5.502 5.045Zm16.5 0v10.09l-5.502-5.045Zm-9.853 4.295a.75.75 0 0 1 .52.242.8.8 0 0 0-.237-.172.8.8 0 0 0-.283-.07m0 0a.8.8 0 0 0-.29.045.8.8 0 0 0-.25.15.75.75 0 0 1 .54-.195m3.207 0a.76.76 0 0 1 .54.197.8.8 0 0 0-.25-.152.8.8 0 0 0-.29-.045m0 0a.8.8 0 0 0-.283.07.8.8 0 0 0-.237.172.8.8 0 0 0-.148.244.75.75 0 0 1 .668-.486m-2.612.342.038.066.025.057.021.058a1 1 0 0 1 .024.088l.006.035a1 1 0 0 1 .008.135 1 1 0 0 1-.02.131l-.01.04a1 1 0 0 1-.033.091l-.02.043a1 1 0 0 1-.042.072l-.03.045a1 1 0 0 1-.09.1 1 1 0 0 0 .09-.1l.03-.045a1 1 0 0 0 .043-.072l.02-.043a1 1 0 0 0 .032-.092l.01-.039a1 1 0 0 0 .02-.13 1 1 0 0 0-.008-.136l-.006-.035a1 1 0 0 0-.024-.088l-.021-.058-.025-.057zm1.938.156a.8.8 0 0 0-.043.283.8.8 0 0 0 .066.281.76.76 0 0 1-.023-.564m.027.57a.8.8 0 0 0 .172.235.7.7 0 0 1-.172-.235m-2.593.7 1.128 1.035a.75.75 0 0 0 1.016 0l1.129-1.035 5.434 4.982H4.826Zm10.912 4.966c.313.287.31.651.146.924a.8.8 0 0 1-.226.248.75.75 0 0 0 .226-.248.75.75 0 0 0-.146-.924m-1.014 1.108a.75.75 0 0 0 .932.064.71.71 0 0 1-.932-.064"
    />
  </svg>
);
export default Icon;
