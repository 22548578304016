import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#prefix__a)">
      <path
        fill="#14267B"
        d="M2.25 10.637a2.25 2.25 0 0 1 2.245 2.096l.005.154v1.828a2.15 2.15 0 0 1 2.383.77l1.234 1.849a5.25 5.25 0 0 1 .876 2.634L9 20.25v3a.75.75 0 0 1-1.493.102L7.5 23.25v-3a3.75 3.75 0 0 0-.5-1.874l-.13-.21-1.161-1.738a.65.65 0 0 0-.918-.205.66.66 0 0 0-.28.694l.032.084 1.352 2.25a.75.75 0 0 1-1.228.856l-.058-.084-1.38-2.302a2.14 2.14 0 0 1-.225-1.004L3 16.637v-3.75a.75.75 0 0 0-1.493-.102l-.007.102v5.171c0 .774.24 1.527.673 2.145l.136.182 2.014 2.38a.75.75 0 0 1-1.068 1.049l-.078-.08-2.027-2.397a5.25 5.25 0 0 1-1.143-3.005L0 18.058v-5.171a2.25 2.25 0 0 1 2.25-2.25m19.5 0a2.25 2.25 0 0 1 2.245 2.096l.005.154v5.171c0 1.1-.346 2.17-.995 3.073l-.168.222-2.014 2.381a.75.75 0 0 1-1.211-.879l.065-.09 2.001-2.364a3.75 3.75 0 0 0 .815-2.111l.007-.232v-5.171a.75.75 0 0 0-1.493-.102l-.007.102v3.75q0 .06-.01.119c0 .283-.055.572-.171.853l-.074.162-1.352 2.25a.75.75 0 0 1-1.332-.681l.046-.091 1.324-2.2a.65.65 0 0 0-1.087-.695l-.071.1-1.142 1.712a3.75 3.75 0 0 0-.623 1.837l-.008.247v3a.75.75 0 0 1-1.493.102L15 23.25v-3c0-.943.254-1.868.733-2.677l.15-.24 1.123-1.681a2.15 2.15 0 0 1 2.494-.942v-1.823a2.25 2.25 0 0 1 2.25-2.25M13.5 0A1.5 1.5 0 0 1 15 1.5v3h3a1.5 1.5 0 0 1 1.493 1.356L19.5 6v3a1.5 1.5 0 0 1-1.5 1.5h-3v3a1.5 1.5 0 0 1-1.355 1.493L13.5 15h-3A1.5 1.5 0 0 1 9 13.5v-3H6a1.5 1.5 0 0 1-1.493-1.356L4.5 9V6A1.5 1.5 0 0 1 6 4.5h3v-3A1.5 1.5 0 0 1 10.355.007L10.5 0zm0 1.5h-3v3.75a.75.75 0 0 1-.75.75H6v3h3.75a.75.75 0 0 1 .75.75v3.75h3V9.75a.75.75 0 0 1 .75-.75H18V6h-3.75a.75.75 0 0 1-.75-.75z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Icon;
