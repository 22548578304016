import classNames from "classnames";
import { cloneElement, type ReactElement } from "react";
import Colors from "ui-kit/colors";
import { Icon } from "../../atoms/Icon";
import styles from "./SlidingPanel.module.css";
import { useSlidingPanel } from "./useSlidingPanel";

type SlidingPanelProps = {
  children: ReactElement | ReactElement[];
  itemMinWidth: number;
  itemMaxWidth: number;
};

export const SlidingPanel = ({
  children,
  itemMinWidth,
  itemMaxWidth,
}: SlidingPanelProps) => {
  const { onNext, onPrevious, ref, showPrev, showNext, onScroll, itemWidth } =
    useSlidingPanel({
      isActive: true,
      itemMinWidth,
      itemMaxWidth,
    });

  return (
    <div className={styles.wrapper}>
      <div
        ref={ref}
        onScroll={onScroll}
        className={classNames(
          styles.container,
          showPrev && styles.showPrev,
          showNext && styles.showNext
        )}
      >
        <div
          className={styles.track}
          style={{ gridTemplateColumns: `repeat(auto-fill, ${itemWidth}px)` }}
        >
          {(Array.isArray(children) ? children : [children]).map((child) =>
            cloneElement(child, {
              style: { ...child.props.style, width: itemWidth },
            })
          )}
        </div>
        <button
          onClick={onPrevious}
          className={classNames(
            styles.button,
            styles.buttonPrev,
            showPrev && styles.buttonShow
          )}
        >
          <Icon name="chevron-left" color={Colors.blue300} />
        </button>
        <button
          onClick={onNext}
          className={classNames(
            styles.button,
            styles.buttonNext,
            showNext && styles.buttonShow
          )}
        >
          <Icon name="chevron-right" color={Colors.blue300} />
        </button>
      </div>
    </div>
  );
};
