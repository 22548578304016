import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill={props.color}
      d="M7.344 10.938a.781.781 0 1 1-1.563 0 .781.781 0 0 1 1.563 0M13.438 11.719a.781.781 0 1 0 0-1.563.781.781 0 0 0 0 1.563"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M7.167 3.47a8.8 8.8 0 0 0-1.791.845L4.097 3.037a1.375 1.375 0 0 0-2.347.972v6.616c0 4.267 3.75 7.619 8.238 7.625h.024c4.489-.006 8.238-3.358 8.238-7.625V4.009a1.375 1.375 0 0 0-2.347-.972l-1.279 1.278a8.8 8.8 0 0 0-1.79-.846.7.7 0 0 0-.206-.067 8.8 8.8 0 0 0-5.256 0 .7.7 0 0 0-.205.067M4.75 5.81l-1.501-1.5v6.314c0 3.1 2.576 5.747 6 6.088V15.31l-1.03-1.03a.75.75 0 1 1 1.06-1.061l.72.72.72-.72a.75.75 0 1 1 1.06 1.06l-1.03 1.03v1.403c3.424-.34 6-2.988 6-6.088V4.31l-1.5 1.5a.75.75 0 0 1-.98.07 7 7 0 0 0-1.02-.616v1.61a.75.75 0 0 1-1.5 0V4.713a7 7 0 0 0-1-.174v2.336a.75.75 0 0 1-1.5 0V4.539a7 7 0 0 0-1 .174v2.162a.75.75 0 1 1-1.5 0v-1.61a7.3 7.3 0 0 0-1.02.617.75.75 0 0 1-.98-.07"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
