import React, { FC, ReactElement } from "react";
import Image from "next/image";
import gif from "../assets/images/lassie_loader.gif";
import gifWhite from "../assets/images/lassie_loader_white_128.gif";

interface LassieLoaderProps {
  size?: number;
  white?: boolean;
}

const LassieLoader: FC<LassieLoaderProps> = ({
  white,
  size = 80,
}): ReactElement => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 24,
        width: 24,
      }}
    >
      {white ? (
        <Image src={gifWhite} alt="loading" width={20} height={20} />
      ) : (
        <Image src={gif} alt="loading" width={20} height={20} />
      )}
    </div>
  );
};

export default LassieLoader;
