import classNames from "classnames";
import Image from "next/image";
import { ReactElement } from "react";
import { Button } from "ui-kit/Button";
import Colors from "ui-kit/colors";
import { Badge } from "../../atoms/Badge";
import { Typography } from "../../Typography";
import styles from "./MiniBanner.module.css";

export const MiniBanner = ({
  title,
  subTitle,
  description,
  badgeText,
  onClick,
  buttonText,
  className,
  imageUrl,
}: {
  title: string;
  subTitle?: string;
  description?: string | ReactElement;
  badgeText?: string;
  onClick?: VoidFunction;
  buttonText: string;
  className?: string;
  imageUrl?: string;
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.contentWrapper}>
        {imageUrl ? (
          <Image
            src={imageUrl}
            alt=""
            width={100}
            height={100}
            className={styles.image}
          />
        ) : null}
        <div className={styles.content}>
          <div className={styles.headlineWrapper}>
            <Typography variant="body2">
              <strong>{title}</strong>
            </Typography>
            {badgeText ? (
              <Badge
                text={badgeText}
                color={Colors.sun200}
                className={styles.badge}
              />
            ) : null}
          </div>
          {subTitle ? (
            <>
              <Typography variant="body3" className={styles.subTitle}>
                {subTitle}
              </Typography>
            </>
          ) : null}
          {description ? (
            <Typography variant="body3" className={styles.description}>
              {description}
            </Typography>
          ) : null}
        </div>
      </div>
      {onClick ? (
        <Button
          variant="custom"
          style={{ background: Colors.blue100 }}
          onPress={onClick}
          title={buttonText}
          textColor="blue300"
        />
      ) : null}
    </div>
  );
};
