import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#14267B"
      fillRule="evenodd"
      d="M5.886 1.357a.75.75 0 0 1 .257 1.029l-.787 1.312a4.25 4.25 0 0 0-.606 2.187V9.5A3.25 3.25 0 0 0 8 12.75h2a3.25 3.25 0 0 0 3.25-3.25V5.885c0-.77-.21-1.526-.606-2.187l-.787-1.312a.75.75 0 1 1 1.286-.772l.788 1.313a5.75 5.75 0 0 1 .819 2.958V9.5A4.75 4.75 0 0 1 10 14.25h-.25V17a4.25 4.25 0 0 0 8.5 0v-5.378a2.25 2.25 0 1 1 1.5 0V17a5.75 5.75 0 1 1-11.5 0v-2.75H8A4.75 4.75 0 0 1 3.25 9.5V5.885a5.75 5.75 0 0 1 .82-2.958l.787-1.313a.75.75 0 0 1 1.029-.257M19 10.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
