import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M12.023 4.795a4.25 4.25 0 1 0 0 8.5 4.25 4.25 0 0 0 0-8.5m-2.75 4.25a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0"
      clipRule="evenodd"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M12.023 1.295c-4.983 0-7.75 4.227-7.75 7.432 0 5.046 2.929 9.333 5.586 12.774a2.738 2.738 0 0 0 4.328 0c2.657-3.441 5.586-7.728 5.586-12.774 0-3.205-2.767-7.432-7.75-7.432m-6.25 7.432c0-2.522 2.233-5.932 6.25-5.932s6.25 3.41 6.25 5.932c0 4.487-2.612 8.41-5.273 11.858a1.238 1.238 0 0 1-1.954 0c-2.661-3.448-5.273-7.371-5.273-11.858"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
