import { Domains, isOrg } from "../utils/site";

export type Language = {
  code: string;
  name: string;
  flag: string;
  domain?: Domains;
  countryName?: string;
};

export const LanguagesMap: Record<string, Language> = {
  sv: {
    code: "sv",
    name: "Svenska",
    flag: "/images/flags/sv.svg",
    domain: Domains.co,
    countryName: "Sverige",
  },
  "en-GB": {
    code: "en",
    name: "English",
    flag: "/images/flags/en_GB.svg",
  },
  de: {
    code: "de",
    name: "Deutsch",
    flag: "/images/flags/de.svg",
    countryName: "Deutschland",
    domain: Domains.de,
  },
  fr: {
    code: "fr",
    name: "Français",
    flag: "/images/flags/fr.svg",
    countryName: "France",
    domain: Domains.fr,
  },
};
