import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionTitle,
} from "@/ui-kit";
import classNames from "classnames";
import { CalendlyPopUp } from "components/ContentfulRenderer/ContentfulComponent/Calendly/PopUp";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useWebTranslation } from "translations";
import { Button } from "ui-kit/Button";
import Gutter from "ui-kit/Gutter";
import { Typography } from "ui-kit/Typography";
import { getMeetingBookingUrl } from "utils/site";
import { LanguageSelector } from "../../LanguageSelector/LanguageSelector";
import { Hamburger } from "./Hamburger";
import styles from "./Mobile.module.css";
import { MenuBarProps, MenuGroup, MenuItem } from "./types";

const TopLevelLink = ({ link }: { link: MenuItem }) => {
  return (
    <Link href={link.uri ?? ""}>
      <Typography variant="body2" className={styles.topLevelLink}>
        <strong>{link.name}</strong>
      </Typography>
    </Link>
  );
};

const SubLevelLink = ({ link }: { link: MenuItem }) => {
  return (
    <Link href={link.uri ?? ""}>
      <Typography variant="body3" className={styles.menuLink}>
        <strong>{link.name}</strong>
      </Typography>
    </Link>
  );
};

const MobileMenu = ({ item }: { item: MenuGroup }) => {
  return (
    <Accordion collapsible>
      <AccordionItem>
        <AccordionTitle>{item.group}</AccordionTitle>
        <AccordionPanel>
          <>
            {item.items.map((menuitem) => {
              return "subMenu" in menuitem ? (
                <div key={menuitem.headline.name}>
                  <Typography variant="body4" className={styles.categoryHeader}>
                    <strong>{menuitem.headline.name}</strong>
                  </Typography>
                  {menuitem.subMenu?.map((subMenuItem) => {
                    return (
                      <SubLevelLink link={subMenuItem} key={subMenuItem.name} />
                    );
                  })}
                </div>
              ) : (
                <Link
                  href={menuitem.headline.uri || ""}
                  key={menuitem.headline.name}
                >
                  <Typography variant="body3" className={styles.menuLink}>
                    <strong>{menuitem.headline.name}</strong>
                  </Typography>
                </Link>
              );
            })}
          </>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export const MenuBarMobile = ({
  navigationItems,
  siteLanguages,
  onMenuToggle,
}: MenuBarProps) => {
  const router = useRouter();
  const { t } = useWebTranslation();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const closeMenu = () => setMobileMenuOpen(false);

    router.events.on("routeChangeStart", closeMenu);

    return () => router.events.off("routeChangeStart", closeMenu);
  }, [router.events]);

  const downloadAppUrl = t("navigation.download_app_cta.url");

  const meetingBookingUrl = getMeetingBookingUrl();

  return (
    <>
      <Hamburger
        onClick={() => {
          onMenuToggle && onMenuToggle(!mobileMenuOpen);
          setMobileMenuOpen(!mobileMenuOpen);
        }}
        isOpen={mobileMenuOpen}
      />
      <div
        className={classNames(styles.backdrop, mobileMenuOpen && styles.active)}
        onClick={() => {
          setMobileMenuOpen(false);
        }}
      />

      <div
        className={classNames(
          styles.mobilePanel,
          mobileMenuOpen && styles.active
        )}
      >
        <nav className={styles.wrapper}>
          {navigationItems.map((item) => {
            return "group" in item ? (
              <MobileMenu item={item} key={item.group} />
            ) : (
              <TopLevelLink link={item} key={item.name} />
            );
          })}

          {downloadAppUrl.length > 0 ? (
            <TopLevelLink
              link={{
                uri: downloadAppUrl,
                name: t("navigation.download_app_cta.label"),
              }}
            />
          ) : null}

          <div className={styles.bottomSectionLinks}>
            <LanguageSelector siteLanguages={siteLanguages} id="menu" />
            {meetingBookingUrl ? (
              <>
                <Gutter size={3} />
                <CalendlyPopUp
                  meetingBookingUrl={meetingBookingUrl}
                  buttonStyles={styles.button}
                  buttonTitle={t("c2a.schedule_call")}
                />
              </>
            ) : null}
            <Button
              title={t("home.sections.0.btn")}
              onPress={() => {
                router.push("/signup");
              }}
              className={styles.signupButton}
            />
          </div>
        </nav>
      </div>
    </>
  );
};
