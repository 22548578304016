import { useDebouncedWindowResizeListener } from "hooks/useDebouncedWindowResizeListener";
import { useCallback, useState } from "react";

const GUTTER = 16;
// How much of the next card we want to show
const PEAKABOO_FACTOR = 0.35;

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export const useSlidingPanel = ({
  isActive = true,
  itemMinWidth,
  itemMaxWidth,
}: {
  isActive?: boolean;
  itemMinWidth: number;
  itemMaxWidth: number;
}) => {
  const [itemWidth, setitemWidth] = useState<number | null>(null);
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);

  function showHideControls(el: HTMLElement) {
    // Hack? Sure. Works? Probably.
    setTimeout(() => {
      setShowPrev(el.scrollLeft !== 0);
      setShowNext(
        el.scrollWidth - el.offsetWidth !== Math.floor(el.scrollLeft)
      );
    }, 0);
  }

  function onScroll() {
    if (!node || !isActive) return;

    showHideControls(node);
  }

  const calculateItemWidth = useCallback(
    (n?: HTMLElement) => {
      const container = node || n;

      if (!container) return;

      const containerWidth = container.clientWidth;
      const numberOfItems = container.children[0].children.length;

      const minimumTotalItemWidth =
        numberOfItems * itemMinWidth + (numberOfItems - 1) * GUTTER;

      // They can fit, spread them out evenly.
      if (minimumTotalItemWidth <= containerWidth) {
        setitemWidth(
          clamp(
            (containerWidth - numberOfItems * GUTTER) / numberOfItems,
            itemMinWidth,
            itemMaxWidth
          )
        );
      } else {
        // They can't fit so let's fit as many as possible while showing a
        // little piece of the next one
        const itemsThatCanFit = Math.floor(
          (containerWidth - GUTTER) / (itemMinWidth + GUTTER)
        );

        setitemWidth(
          (containerWidth - itemMinWidth * PEAKABOO_FACTOR) / itemsThatCanFit
        );
      }

      showHideControls(container);
    },
    [itemMaxWidth, itemMinWidth, node]
  );

  useDebouncedWindowResizeListener(calculateItemWidth);

  const ref = useCallback(
    (n: HTMLDivElement) => {
      if (n) {
        showHideControls(n);
        setNode(n);
        calculateItemWidth(n);
      }
    },
    [calculateItemWidth]
  );

  function onNext() {
    if (!node || !isActive || !itemWidth) return;

    const numberOfVisible = Math.floor(node.clientWidth / (itemWidth + GUTTER));

    node.scrollLeft =
      Math.floor(
        (node.scrollLeft + GUTTER + numberOfVisible * (itemWidth + GUTTER)) /
          (itemWidth + GUTTER)
      ) *
      (itemWidth + GUTTER);
  }

  function onPrevious() {
    if (!node || !isActive || !itemWidth) return;

    const visibleOfLastCard =
      itemWidth + GUTTER - (node.scrollLeft % (itemWidth + GUTTER));

    node.scrollLeft -= node.clientWidth - visibleOfLastCard + GUTTER;
  }

  return {
    onNext,
    onPrevious,
    onScroll,
    ref,
    showNext,
    showPrev,
    itemWidth,
  };
};
