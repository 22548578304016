import { useEffect, useMemo } from "react";
import debounce from "lodash.debounce";

export const useDebouncedWindowResizeListener = (callback: VoidFunction) => {
  const debouncedCallback = useMemo(() => debounce(callback, 200), [callback]);

  useEffect(() => {
    window.addEventListener("resize", debouncedCallback);

    return () => {
      window.removeEventListener("resize", debouncedCallback);
    };
  }, [debouncedCallback]);
};
