import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M8.205 3.705c.439-.44 1.151-.44 1.59 0l7.5 7.5c.44.439.44 1.151 0 1.59l-7.5 7.5a1.125 1.125 0 0 1-1.59-1.59L14.909 12 8.204 5.296a1.125 1.125 0 0 1 0-1.592"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
