import InnerLayout from "components/InnerLayout/InnerLayout";
import styles from "./ErrorPage.module.css";
import { Typography } from "ui-kit/Typography";

import { useRouter } from "next/router";
import Image from "next/image";
import { LayoutPage } from "./Layout";
import { ContentfulHeaderProps } from "contentful/getContentfulHeader";
import classNames from "classnames";
import { Button } from "ui-kit/Button";
import Colors from "ui-kit/colors";

type ErrorComponentProps = {
  buttonOnPress?: VoidFunction;
  buttonText?: string;
  description?: string;
  imgSrc?: string;
  title: string | React.ReactElement;
};

type ErrorPageProps = ContentfulHeaderProps &
  ErrorComponentProps & {
    metaDescription: string;
    metaTitle: string;
  };

export const ErrorComponentFullPage = ({
  title,
  description,
  imgSrc,
  buttonOnPress,
  buttonText,
}: ErrorComponentProps) => {
  return (
    <div className={styles.fullpageLayout}>
      <div className={styles.fullpageInnerLayout}>
        <ErrorComponent
          title={title}
          buttonOnPress={buttonOnPress}
          buttonText={buttonText}
          description={description}
          imgSrc={imgSrc}
        />
      </div>
    </div>
  );
};

export const ErrorComponent = ({
  title,
  description,
  imgSrc,
  buttonOnPress,
  buttonText,
}: ErrorComponentProps) => {
  return (
    <>
      <div className={styles.innerLayout}>
        <Typography variant="h1" color="blue300" className={styles.title}>
          {title}
        </Typography>
        {description ? (
          <Typography variant="body2" className={styles.description}>
            {description}
          </Typography>
        ) : null}
        {imgSrc ? (
          <Image
            src={imgSrc}
            alt="Lassie"
            priority
            width={250}
            height={250}
            sizes="(max-width: 500px) 250px"
            className={classNames(styles.image, styles.mobileImage)}
          />
        ) : null}
        {buttonText && buttonOnPress ? (
          <Button
            onPress={buttonOnPress}
            variant="default"
            title={buttonText}
            style={{ width: "fit-content", minWidth: 280, marginTop: 40 }}
          />
        ) : null}
      </div>
      {imgSrc ? (
        <Image
          src={imgSrc}
          alt="Lassie"
          priority
          width={375}
          height={375}
          sizes="(max-width: 500px) 250px"
          className={classNames(styles.image, styles.desktopImage)}
        />
      ) : null}
    </>
  );
};

export const ErrorPage = ({
  buttonOnPress,
  buttonText,
  description,
  imgSrc,
  metaDescription,
  metaTitle,
  navigationItems,
  siteLanguages,
  title,
}: ErrorPageProps) => {
  return (
    <LayoutPage
      navigationItems={navigationItems}
      siteLanguages={siteLanguages}
      slim
      title={metaTitle}
      description={metaDescription}
      background={Colors.errorBg}
    >
      <InnerLayout
        backgroundColor={Colors.errorBg}
        hideHeader
        className={styles.layout}
      >
        <ErrorComponent
          title={title}
          description={description}
          buttonOnPress={buttonOnPress}
          buttonText={buttonText}
          imgSrc={imgSrc}
        />
      </InnerLayout>
    </LayoutPage>
  );
};
