import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M12 2.25a.75.75 0 0 1 .75.75v.755a49 49 0 0 1 9.15 1 .75.75 0 1 1-.3 1.47 49 49 0 0 0-1.855-.344l2.354 9.637c.196.802-.157 1.759-1.064 2.086A6.8 6.8 0 0 1 18.75 18a6.7 6.7 0 0 1-2.284-.396c-.908-.327-1.26-1.284-1.066-2.085v-.001l2.423-9.913a48 48 0 0 0-5.073-.35v14.267c1.293.075 2.534.343 3.697.775a.75.75 0 0 1-.524 1.406A11.2 11.2 0 0 0 12 21c-1.381 0-2.703.249-3.923.703a.75.75 0 0 1-.524-1.406c1.163-.433 2.405-.7 3.697-.775V5.256a48 48 0 0 0-5.073.348l2.422 9.914c.196.802-.157 1.759-1.064 2.086A6.8 6.8 0 0 1 5.25 18a6.7 6.7 0 0 1-2.284-.396c-.908-.327-1.26-1.284-1.066-2.085l2.355-9.638q-.933.155-1.854.344a.75.75 0 0 1-.302-1.47 49.158 49.158 0 0 1 9.15-1V3a.75.75 0 0 1 .751-.75M5.25 8.13l-1.892 7.743a.35.35 0 0 0 .018.225c.024.052.057.08.097.094a5.2 5.2 0 0 0 1.776.308h.002a5.2 5.2 0 0 0 1.777-.308.17.17 0 0 0 .096-.093.34.34 0 0 0 .017-.225zm0 9.87zl.001-.75.001.75zm13.5-9.87-1.892 7.743a.35.35 0 0 0 .018.225c.024.052.057.08.097.094.57.205 1.17.309 1.776.308h.002c.606 0 1.207-.103 1.777-.308a.17.17 0 0 0 .096-.093.34.34 0 0 0 .018-.225zm0 9.87h-.001l.001-.75.001.75z"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
