import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M15.395 17.891 12 16.05l-3.395 1.834c-.622.337-1.382-.162-1.219-.793l.9-3.463-3.002-2.333c-.548-.425-.254-1.232.466-1.284l3.951-.3 1.546-3.265c.278-.595 1.228-.595 1.506 0l1.546 3.272 3.95.3c.72.052 1.015.86.467 1.285l-3.002 2.333.9 3.463c.163.63-.597 1.13-1.22.792M12 14.344l-2.735 1.478.72-2.772-2.176-1.691 2.874-.219 1.315-2.777 1.316 2.784 2.875.22-2.176 1.69.72 2.769z"
      clipRule="evenodd"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1M2.375 12a9.625 9.625 0 1 1 19.25 0 9.625 9.625 0 0 1-19.25 0"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
