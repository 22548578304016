import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#prefix__a)">
      <path
        fill="#14267B"
        d="M18.879 1.012a3 3 0 0 1 4.243 4.242l-10.61 10.608a1.5 1.5 0 0 1-2.12 0L9.332 14.8l-1.654 1.654c1.212 1.834.997 4.302-.543 5.853l-.165.158a4.7 4.7 0 0 1-6.626.315.75.75 0 0 1-.027-1.086L6.15 15.86l2.12-2.121c-.247-.246-.4-.57-.433-.913l-.008-.148c0-.398.159-.78.44-1.06zM6.583 17.549l-4.578 4.578.135.067c1.181.552 2.625.334 3.63-.643l.14-.145a3.2 3.2 0 0 0 .741-3.723zM22.061 2.072a1.5 1.5 0 0 0-2.122 0L9.331 12.68l.52.518.013.013.01.012 1.577 1.578 10.61-10.606a1.5 1.5 0 0 0 0-2.122"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Icon;
