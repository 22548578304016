import { useCustomEventListener } from "hooks/useCustomEvent";
import { useCallback, useEffect, useRef, useState } from "react";

const SCROLL_TRESHHOLD = 20;
const DESKTOP_BREAKPOINT = 1200;

export function useStickyNavigation() {
  const [hide, setHide] = useState(false);
  const lastScrollPosition = useRef<number>(0);
  const isMenuOpen = useRef<boolean>(false);

  const setIsDisabled = useCallback((state: boolean) => {
    isMenuOpen.current = state;
  }, []);

  useEffect(() => {
    function onScroll() {
      if (
        window.scrollY > lastScrollPosition.current + SCROLL_TRESHHOLD &&
        !isMenuOpen.current &&
        // Scroll past the entire header before hiding it
        window.scrollY > 80 &&
        window.innerWidth <= DESKTOP_BREAKPOINT
      ) {
        setHide(true);
        lastScrollPosition.current = window.scrollY;
      }

      if (
        window.scrollY < lastScrollPosition.current - SCROLL_TRESHHOLD ||
        window.scrollY < SCROLL_TRESHHOLD
      ) {
        setHide(false);
        lastScrollPosition.current = window.scrollY;
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return { hide, setIsDisabled };
}
