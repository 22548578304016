import { SiteNavigation_siteCollection_items_navigationCollection_items } from "../../../__generated__/SiteNavigation";
import { MenuGroup, MenuItem } from "./MenuBar/types";

export const transformNavigation = (
  navigationItems: (SiteNavigation_siteCollection_items_navigationCollection_items | null)[]
) => {
  if (!navigationItems) {
    return [];
  }
  const res: Array<MenuItem | MenuGroup> = [];
  navigationItems.forEach((item) => {
    if (!item || !item.title) {
      return;
    }
    // if both page and url is set we default to page url
    const itemLink = item?.page?.slug || item?.url?.url || null;
    if (!item?.group) {
      res.push({ uri: itemLink, name: item.title });
      return;
    }
    const foundGroup = res.find(
      (i) => (i as MenuGroup).group === item.group
    ) as MenuGroup;
    if (foundGroup) {
      if (item.groupLevel === "subcategory") {
        const lastHeadlineItem = foundGroup.items[foundGroup.items.length - 1];
        lastHeadlineItem.subMenu = lastHeadlineItem.subMenu
          ? [
              ...lastHeadlineItem.subMenu,
              {
                name: item.title!,
                uri: itemLink,
              },
            ]
          : [
              {
                name: item.title!,
                uri: itemLink,
              },
            ];
        return;
      }
      foundGroup.items.push({
        headline: {
          name: item.title!,
          uri: itemLink,
        },
      });
      return;
    }
    res.push({
      group: item.group,
      direction: "left",
      items: [
        {
          headline: {
            name: item.title!,
            uri: itemLink,
          },
        },
      ],
    });
  });

  const lastNavbarGroup = res[res.length - 1];
  if ((lastNavbarGroup as MenuGroup)?.group) {
    (lastNavbarGroup as MenuGroup).direction = "right";
  }

  return res;
};
