const I18NextHttpBackend = require("i18next-http-backend");
const {
  getLocalesByDomain,
  getDefaultLocaleByDomain,
} = require("./config.utils");

const DOMAIN = process.env.DOMAIN || process.env.NEXT_PUBLIC_DOMAIN;

if (!DOMAIN) {
  throw new Error("Env var for DOMAIN not set");
}

/** @type import("next-i18next").UserConfig */
const config = {
  i18n: {
    debug: false, // process.env.NODE_ENV === "development",
    localeDetection: false,
    defaultLocale: getDefaultLocaleByDomain(DOMAIN),
    locales: getLocalesByDomain(DOMAIN),
    backend: {
      crossDomain: true,
      request: async (options, url, payload, callback) => {
        const MAX_RETRY = 3;
        let retried = 0;
        do {
          const ret = await fetch(url);
          if (ret.ok) {
            const data = await ret.json();
            return callback(null, {
              status: 200,
              data: JSON.stringify(data),
            });
          }

          // Wait one second
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 1000);
          });
          retried += 1;
          console.warn(
            `translations missing on url ${url}, retrying ${retried} time`
          );
        } while (retried < MAX_RETRY);

        // do not allow the build if translations missing
        throw new Error(
          `Failed to load translations from ${url} after ${retried} retries`
        );
      },
      loadPath: (lng, namespaces) => {
        if (namespaces.includes("web")) {
          const webLng = lng.includes("en") ? "en_GB" : lng;
          return `https://lassie-lokalise.s3.eu-central-1.amazonaws.com/web/locale/${webLng}.json`;
        }
        // the locale for app is 'en' instead of 'en_GB'
        return `https://lassie-lokalise.s3.eu-central-1.amazonaws.com/locale/${lng}.json`;
      },
    },
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "li", "ol"],
    },
  },
  serializeConfig: false,
  ns: ["app", "web"],
  defaultNS: "web",
  use: [I18NextHttpBackend],
};

module.exports = config;
