import React, { FC } from "react";
import Link from "next/link";
import Image from "next/image";
import styles from "./Header.module.css";
import { SiteNavigation_siteCollection_items_navigationCollection_items } from "../../../__generated__/SiteNavigation";
import { getOrg } from "utils/site";
import { TopLevelLink } from "./MenuBar/components";
import { useWebTranslation } from "translations";
import classNames from "classnames";
import { useStickyNavigation } from "./useStickyNavigation";
import { MenuBarMobile } from "./MenuBar/Mobile";
import { MenuBarDesktop } from "./MenuBar/Desktop";
import { transformNavigation } from "./transformNavigation";

interface IHeaderProps {
  navigationItems: (SiteNavigation_siteCollection_items_navigationCollection_items | null)[];
  siteLanguages: (string | null)[] | null | undefined;
}

const Header: FC<IHeaderProps> = ({ navigationItems, siteLanguages }) => {
  const { t } = useWebTranslation();

  const { hide, setIsDisabled } = useStickyNavigation();

  const transformedNavigation = transformNavigation(navigationItems);

  const downloadAppUrl = t("navigation.download_app_cta.url");

  return (
    <div className={classNames(styles.container, hide && styles.hideHeader)}>
      <div className={styles.content}>
        <>
          <Link href="/" className={styles.flex}>
            <Image
              src="/images/logo-with-name.svg"
              className={styles.logo}
              alt="Lassie"
              width={105}
              height={27}
            />
          </Link>

          <div className={styles.mobileContainer}>
            <MenuBarMobile
              navigationItems={transformedNavigation}
              onMenuToggle={setIsDisabled}
            />
          </div>
          <div className={styles.desktopContainer}>
            <MenuBarDesktop
              navigationItems={transformedNavigation}
              onMenuToggle={setIsDisabled}
            />
          </div>
          <div className={styles.desktopContainer}>
            {downloadAppUrl.length > 0 ? (
              <TopLevelLink
                href={t(`${getOrg()}.navigation.download_app_cta.url`)}
                label={t("navigation.download_app_cta.label")}
              />
            ) : null}
            <Link href="signup" className={styles.linkButton}>
              {t("home.sections.0.btn")}
            </Link>
          </div>
        </>
      </div>
    </div>
  );
};

export default Header;
