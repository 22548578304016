import { init } from "@sentry/nextjs";
import { inProduction } from "./config";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.1,
  enabled: inProduction,
});
