import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 18.33v2.25"
    />
    <path
      fill={props.color}
      d="M8.625 14.392a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875M15.375 14.392a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m13.5 16.83-1.5 1.5-1.5-1.5M14.25 4.83l5.228-1.308a.75.75 0 0 1 .919.59l1.539 8.21c.145.77-.842 1.218-1.327.6zM9.75 4.83 4.522 3.521a.75.75 0 0 0-.919.59l-1.539 8.21c-.144.77.842 1.218 1.327.6zM9.75 4.83h4.5"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.5 11.51v6.069a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3V11.51"
    />
  </svg>
);
export default Icon;
