enum Colors {
  blue100 = "#E2EBFF",
  blue200 = "#B3BCDB",
  blue300 = "#14267B",
  green100 = "#D7E6D1",
  green200 = "#63A881",
  green300 = "#235545",
  orange100 = "#FFE8C6",
  orange200 = "#F7B228",
  orange300 = "#EE7E52",
  pink100 = "#F9D8CB",
  pink200 = "#FFBDA4",
  pink300 = "#F46057",
  grey100 = "#F2F2F7",
  grey200 = "#E6E6EC",
  grey300 = "#D1D1D1",
  grey400 = "#7D7D84",
  grey500 = "#535457",
  grey600 = "#F2F2F2",
  gold100 = "#535457",
  gold200 = "#B38C41",
  gold400 = "#FFE085",
  gold500 = "#F4C76E",
  black = "#000000",
  white = "#FFFFFF",
  error = "#E9283A",
  errorBg = "#FFE7E9",
  offwhite = "#FAFAFA",

  navy100 = "#F8FAFE",
  navy200 = "#E2EBFF",
  navy300 = "#B3BCDB",
  navy400 = "#7E8ABB",
  navy500 = "#49589B",
  /* Colors.blue300 / brand bl */
  navy600 = "#14267B",

  /* sun is disclaimer */
  sun100 = "#FFFBF4",
  sun200 = "#FEF2DC",
  sun300 = "#FBD996",
  sun400 = "#F8C04F",
  sun500 = "#F7B228",
  sun600 = "#BF8207",

  /* forrest is success */
  forrest100 = "#EBF6F2",
  forrest200 = "#C4E5D8",
  forrest300 = "#9CD4BD",
  forrest400 = "#75C3A3",
  forrest500 = "#4DB289",
  forrest600 = "#3C8A6A",

  /* red is error */
  red100 = "#FEF4F4",
  red200 = "#FBE7E7",
  red300 = "#F2CCCC",
  red400 = "#EBB1B1",
  red500 = "#E68585",
  red600 = "#CC2626",
}

export type Color = keyof typeof Colors;

export default Colors;
