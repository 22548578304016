import Image from "next/image";
import { components, OptionProps, SingleValueProps } from "react-select";
import { Typography } from "ui-kit/Typography";
import { LanguageOption } from "./types";

export const Option = ({ children, ...props }: OptionProps<LanguageOption>) => {
  return (
    <components.Option {...props}>
      <Typography variant="body3">
        <strong style={{ display: "flex", justifyContent: "center" }}>
          <>
            <Image
              src={`/images/flags/${props.data.flag}.svg`}
              alt=""
              width="24"
              height="24"
              style={{ marginInlineEnd: 8 }}
            />
            {children}
          </>
        </strong>
      </Typography>
    </components.Option>
  );
};

export const SingleValue = ({
  children,
  ...props
}: SingleValueProps<LanguageOption>) => {
  return (
    <components.SingleValue {...props}>
      <Image
        src={`/images/flags/${props.data.flag}.svg`}
        alt=""
        width="24"
        height="24"
        style={{ marginInlineEnd: 8 }}
      />
      <Typography variant="body2">
        <strong>
          <>
            {props.data.countryName} · {children}
          </>
        </strong>
      </Typography>
    </components.SingleValue>
  );
};
