import React, { FC } from "react";

type Props = {
  name: string;
  size: number;
  color: string;
  styles?: React.CSSProperties;
  className?: string;
};

const Icon: FC<Props> = ({ name, size, color, styles, className = "" }) => (
  <i
    className={`icon-${name} ${className}`}
    style={{ color, fontSize: size, ...styles }}
  />
);

export default Icon;
