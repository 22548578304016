import React, { FC } from "react";
import styles from "./InnerLayout.module.css";
import classNames from "classnames";

const InnerLayout: FC<{
  children: React.ReactNode;
  hideHeader?: boolean;
  backgroundColor?: string;
  disableHeight?: boolean;
  dividerBackground?: string;
  className?: string;
}> = ({
  disableHeight = false,
  hideHeader = false,
  backgroundColor = "",
  dividerBackground = "",
  children,
  className,
}) => (
  <div
    className={styles.container}
    style={{
      background: backgroundColor || undefined,
      minHeight: disableHeight ? "auto" : undefined,
    }}
  >
    <div className={classNames(styles.content, className)}>{children}</div>
  </div>
);

export default InnerLayout;
