import React from "react";
import Link, { LinkProps } from "next/link";
import { ReactComponentLike } from "prop-types";
import Colors from "../ui-kit/colors";

type LinkTProps = LinkProps & { children?: string[] };

export const LinkT = (props: LinkTProps) => {
  const { children, ...rest } = props;
  if (!children) {
    return null;
  }
  return (
    <Link {...rest} legacyBehavior>
      {children[0]}
    </Link>
  );
};

type EmailTProps = { children?: string; color?: string };

export const EmailT = ({ children, color }: EmailTProps) => {
  return (
    <a style={{ color: color || Colors.blue300 }} href={`mailto:${children}`}>
      {children}
    </a>
  );
};

// eslint-disable-next-line react/display-name
export const transHOC = (Component: ReactComponentLike) => (props: any) => {
  const { children, ...rest } = props;
  if (!children) {
    return null;
  }
  return <Component {...rest}>{children[0]}</Component>;
};
