import { useRouter } from "next/router";
import { getResolvedDomain } from "utils/site";

export function useCanonicalUrl() {
  const { defaultLocale, locale, asPath } = useRouter();

  return new URL(
    defaultLocale !== locale ? `${locale}${asPath}` : asPath,
    `https://${getResolvedDomain()}`
  ).toString();
}
