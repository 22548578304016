import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#14267B"
      fillRule="evenodd"
      d="M10.923 2.862c.502.143.781.218.983.218.038 0 .133-.01.32-.042.13-.023.274-.052.442-.085l.202-.04a17 17 0 0 1 1.823-.269c1.35-.11 3.048.002 4.38 1.11 2.03 1.686 2.34 3.865 1.954 5.776-.342 1.69-1.23 3.216-1.987 4.169.294 1.567.472 2.804.552 3.78.085 1.039.064 1.83-.071 2.427-.14.616-.419 1.098-.895 1.386-.437.265-.924.288-1.271.288-.65 0-1.096-.36-1.378-.765-.262-.377-.428-.848-.553-1.254q-.084-.273-.15-.508a13 13 0 0 0-.212-.686c-.128-.368-.244-.58-.346-.686-.342-.356-.597-.466-.877-.516-.264-.047-.54-.043-.956-.038-.155.002-.328.004-.528.004-.654 0-1.248.034-1.726.173-.46.134-.732.34-.876.64-.066.137-.128.353-.225.692l-.04.143c-.11.381-.252.853-.465 1.29s-.529.905-1.023 1.216c-.518.326-1.143.427-1.856.28-.824-.172-1.25-.842-1.458-1.483-.209-.645-.262-1.434-.243-2.217.036-1.455.33-3.1.613-4.271-.53-1.002-1.222-2.614-1.556-4.265-.354-1.746-.368-3.86 1.095-5.163C6.305 2.644 7.888 2.359 9.24 2.5c.648.068 1.226.231 1.683.362m-1.839 1.13c-.95-.1-2.11.064-3.491 1.294-.813.723-.961 2.074-.623 3.746.326 1.612 1.05 3.222 1.539 4.092a.75.75 0 0 1 .072.554c-.286 1.116-.604 2.799-.639 4.223-.017.72.04 1.312.171 1.72.133.41.277.463.336.475.39.08.609.01.75-.08.166-.104.325-.296.476-.604s.262-.667.37-1.046l.04-.137c.087-.31.19-.671.316-.935.39-.81 1.096-1.223 1.81-1.43.696-.203 1.473-.233 2.144-.233q.194 0 .392-.004c.465-.006.938-.013 1.356.062.62.11 1.154.39 1.694.952.34.353.541.828.682 1.234.089.255.176.556.256.83.043.15.084.29.122.412.123.398.233.671.35.84.09.128.129.125.144.123h.004c.315 0 .435-.035.494-.07.022-.014.128-.078.209-.435.085-.377.12-.99.039-1.973-.08-.973-.268-2.262-.596-3.969a.75.75 0 0 1 .172-.635c.672-.769 1.569-2.21 1.884-3.766.306-1.515.06-3.077-1.443-4.325-.875-.728-2.078-.867-3.3-.767-.6.048-1.167.15-1.658.246l-.184.036c-.174.035-.34.067-.486.093a3.5 3.5 0 0 1-.58.065m-2.822-.588c.503.052.966.18 1.428.312Zm1.428.312.079.023Zm.079.023c.402.115.883.253 1.315.253Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
