import classNames from "classnames";
import Colors from "ui-kit/colors";
import { Icon, IconProps } from "../Icon";
import styles from "./IconFrame.module.css";

export const IconFrame = ({
  icon,
  iconSize = "M",
  className,
  style = "normal",
  iconColor,
}: {
  icon: IconProps["name"];
  iconSize?: IconProps["size"];
  className?: string;
  style?: "normal" | "round";
  iconColor?: Colors;
}) => {
  return (
    <div className={classNames(styles.frame, styles[style], className)}>
      <Icon
        name={icon}
        size={iconSize}
        color={style === "round" ? Colors.blue300 : iconColor}
      />
    </div>
  );
};
