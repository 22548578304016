import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M14.615 1.095a.75.75 0 0 1 .359.852L12.982 9.25h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143M5.475 12.25H12a.75.75 0 0 1 .724.947l-1.452 5.323 7.252-7.77H12a.75.75 0 0 1-.724-.947l1.452-5.323z"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
