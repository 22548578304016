import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M15.796 3.705c.439.439.439 1.151 0 1.59L9.09 12l6.705 6.704a1.125 1.125 0 0 1-1.591 1.592l-7.5-7.5a1.125 1.125 0 0 1 0-1.591l7.5-7.5c.439-.44 1.151-.44 1.59 0"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
