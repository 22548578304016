import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      d="M8 12.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75M8.75 15a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5z"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M5.75 2A1.75 1.75 0 0 0 4 3.75v16c0 .966.784 1.75 1.75 1.75h12a1.75 1.75 0 0 0 1.75-1.75V8.664c0-.464-.184-.909-.513-1.237l-4.914-4.914A1.75 1.75 0 0 0 12.836 2zM12 3.5H5.75a.25.25 0 0 0-.25.25v16c0 .138.112.25.25.25h12a.25.25 0 0 0 .25-.25V9h-4.25A1.75 1.75 0 0 1 12 7.25zm4.94 4h-3.19a.25.25 0 0 1-.25-.25V4.06z"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
