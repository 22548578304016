import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#prefix__a)">
      <path
        fill="#14267B"
        d="m17.886.403.084.073 5.304 5.304a.75.75 0 0 1-.976 1.133l-.084-.073-1.062-1.061-2.651 2.652 2.651 2.652a.75.75 0 0 1-.976 1.133l-.084-.073-1.064-1.063-2.617 2.62-.032.034-.034.029-3.116 3.12-.032.033-.034.029-2.62 2.62a2.25 2.25 0 0 1-3.05.122l-.129-.119-2.121 2.122a.75.75 0 0 1-.977.073l-.084-.073-.531-.531-2.12 2.121a.75.75 0 0 1-1.134-.976l.073-.084 2.12-2.122-.529-.53a.75.75 0 0 1-.073-.976l.073-.084 2.121-2.121a2.25 2.25 0 0 1-.651-1.394l-.009-.198c0-.597.238-1.17.66-1.591l8.484-8.486-1.06-1.06a.75.75 0 0 1 .977-1.133l.084.073 2.652 2.651 2.651-2.652-1.06-1.06a.75.75 0 0 1 .976-1.134M5.242 17.448l-1.59 1.59.514.514.016.016.014.016.516.515 1.59-1.59zm8.485-11.669-8.485 8.486a.75.75 0 0 0 0 1.06L7.9 17.985l.523.522a.75.75 0 0 0 1.06-.001l2.12-2.122-1.587-1.588a.75.75 0 0 1 .976-1.133l.084.073 1.588 1.587 2.121-2.121-1.587-1.588a.75.75 0 0 1 .976-1.133l.084.073 1.588 1.587 2.121-2.121zm5.304-2.12-2.652 2.65L17.44 7.37l2.651-2.65z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Icon;
