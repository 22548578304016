import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#14267B"
      d="M4 2.75c-.69 0-1.25.56-1.25 1.25v2a.75.75 0 0 1-1.5 0V4A2.75 2.75 0 0 1 4 1.25h2a.75.75 0 0 1 0 1.5zM6.75 8a.75.75 0 0 0-1.5 0v8.5a.75.75 0 0 0 1.5 0zM9 7.25a.75.75 0 0 1 .75.75v8.5a.75.75 0 0 1-1.5 0V8A.75.75 0 0 1 9 7.25M18 7.25a.75.75 0 0 1 .75.75v8.5a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75M15 7.25a.75.75 0 0 1 .75.75v8.5a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75M12.75 8a.75.75 0 0 0-1.5 0v8.5a.75.75 0 0 0 1.5 0zM2.75 20c0 .69.56 1.25 1.25 1.25h2a.75.75 0 0 1 0 1.5H4A2.75 2.75 0 0 1 1.25 20v-2a.75.75 0 0 1 1.5 0zM20 2.75c.69 0 1.25.56 1.25 1.25v2a.75.75 0 0 0 1.5 0V4A2.75 2.75 0 0 0 20 1.25h-2a.75.75 0 0 0 0 1.5zM21.25 20c0 .69-.56 1.25-1.25 1.25h-2a.75.75 0 0 0 0 1.5h2A2.75 2.75 0 0 0 22.75 20v-2a.75.75 0 0 0-1.5 0z"
    />
  </svg>
);
export default Icon;
