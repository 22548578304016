import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M6.815 5.244c-.136-.496-.493-.75-.815-.744a3.1 3.1 0 0 0-.748.096c-.212.058-.338.133-.405.197.001.001-.045.066-.106.256a4.7 4.7 0 0 0-.172.835c-.088.705-.103 1.642.02 2.702.248 2.125 1.037 4.673 2.79 6.815 1.715 2.096 4.115 3.072 6.339 3.468 2.234.397 4.2.19 4.92.01.164-.04.276-.137.363-.37.097-.263.128-.636.096-1.054-.022-.298-.274-.612-.732-.737l-2.353-.642a1.25 1.25 0 0 0-1.213.322l-.837.837a.75.75 0 0 1-.654.21c-1.887-.315-5.81-2.194-7.23-7.167a.75.75 0 0 1 .19-.736l.838-.838c.317-.317.44-.78.322-1.212zM5.967 3c1.193-.026 2.031.882 2.295 1.848l.613 2.248a2.75 2.75 0 0 1-.709 2.668l-.518.518c1.218 3.677 4.024 5.203 5.542 5.603l.549-.549a2.75 2.75 0 0 1 2.668-.708l2.353.641c.892.244 1.75.975 1.833 2.072.04.52.018 1.137-.185 1.687-.214.578-.65 1.116-1.406 1.306-.937.234-3.117.442-5.546.01-2.44-.433-5.222-1.53-7.238-3.994C4.24 13.933 3.371 11.09 3.1 8.76c-.136-1.168-.124-2.226-.019-3.063a6 6 0 0 1 .232-1.11c.094-.29.236-.617.468-.849.312-.312.706-.487 1.072-.588.37-.102.76-.142 1.114-.15"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
