import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M2.813 3.125c-.863 0-1.563.7-1.563 1.563v8.125a1.563 1.563 0 0 0 1.563 1.562h14.375c.862 0 1.562-.7 1.562-1.562V4.687c0-.862-.7-1.562-1.562-1.562zm.937 1.25a1.25 1.25 0 0 1-1.25 1.25v6.25a1.25 1.25 0 0 1 1.25 1.25h12.5a1.25 1.25 0 0 1 1.25-1.25v-6.25c-.69 0-1.25-.56-1.25-1.25zm6.25 2.5a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75M7.79 6.54a3.125 3.125 0 1 1 4.42 4.42 3.125 3.125 0 0 1-4.42-4.42M4.375 8.75c0-.345.28-.625.625-.625h.007c.345 0 .625.28.625.625v.007c0 .345-.28.625-.625.625H5a.625.625 0 0 1-.625-.625zm10 0c0-.345.28-.625.625-.625h.007c.345 0 .625.28.625.625v.007c0 .345-.28.625-.625.625H15a.625.625 0 0 1-.625-.625zm.5 9.229a49.4 49.4 0 0 0-13-1.729.625.625 0 1 1 0-1.25 50.7 50.7 0 0 1 13.328 1.773.33.33 0 0 0 .422-.31v-.838a.625.625 0 1 1 1.25 0v.837c0 1.052-1.008 1.787-2 1.517"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon;
