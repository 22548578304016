import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#prefix__a)">
      <path
        fill="#14267B"
        d="M21.5 0a2.25 2.25 0 0 1 2.25 2.25V4.5a1.5 1.5 0 0 1-1.5 1.5v15.75a2.25 2.25 0 0 1-2.096 2.245L20 24h-9a2.25 2.25 0 0 1-2.25-2.25.75.75 0 0 1 1.493-.102l.007.102c0 .38.282.694.648.743L11 22.5h9a.75.75 0 0 0 .743-.648l.007-.102-.001-2.25H14a.75.75 0 0 1-.743-.648l-.007-.102v-7.5a.75.75 0 0 1 .75-.75h6.749L20.75 6h-10.5v5.25a.75.75 0 0 1-.648.743L9.5 12a.75.75 0 0 1-.743-.648l-.007-.102V6a1.5 1.5 0 0 1-1.5-1.5V2.25A2.25 2.25 0 0 1 9.5 0zM6.57 13.64a3.037 3.037 0 1 1 4.295 4.296l-5.18 5.174a3.038 3.038 0 1 1-4.295-4.296l2.578-2.577.01-.01.009-.009zm-2.062 4.179L2.45 19.875a1.538 1.538 0 1 0 2.175 2.174l2.057-2.056zm4.607-3.517a1.54 1.54 0 0 0-1.485.399l-2.06 2.058 2.174 2.174 2.06-2.058c.354-.353.511-.853.43-1.34l-.031-.145a1.54 1.54 0 0 0-1.088-1.088M20.75 12h-6v6h6zm.75-10.5h-12a.75.75 0 0 0-.75.75V4.5h13.5V2.25a.75.75 0 0 0-.75-.75"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Icon;
