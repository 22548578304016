import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#prefix__a)">
      <path
        fill={props.color}
        d="M12-.001c.72 0 1.403.326 1.854.888l1.513 1.882a.88.88 0 0 0 .78.323l2.398-.26a2.38 2.38 0 0 1 2.629 2.626l-.261 2.4a.88.88 0 0 0 .324.78l1.881 1.513a2.38 2.38 0 0 1-.002 3.71l-1.885 1.503a.88.88 0 0 0-.324.782l.26 2.398a2.38 2.38 0 0 1-2.626 2.629l-2.4-.261a.88.88 0 0 0-.78.324l-1.506 1.874a2.38 2.38 0 0 1-3.71 0l-1.51-1.88a.88.88 0 0 0-.782-.324l-2.397.26a2.378 2.378 0 0 1-2.63-2.626l.261-2.4a.88.88 0 0 0-.324-.781l-1.88-1.511a2.38 2.38 0 0 1 0-3.71l1.88-1.512a.88.88 0 0 0 .324-.78l-.26-2.398A2.38 2.38 0 0 1 5.453 2.82l2.4.261a.88.88 0 0 0 .783-.326l1.51-1.867A2.38 2.38 0 0 1 12-.001m4.2 9.15a.75.75 0 0 0-1.05.15l-3.981 5.308-2.389-2.388-.084-.073a.75.75 0 0 0-.976 1.133l2.389 2.389.12.108a1.5 1.5 0 0 0 2.14-.269L16.35 10.2l.06-.094a.75.75 0 0 0-.21-.956"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Icon;
